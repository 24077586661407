import React from "react";
import './LoginModal.css';
import {Redirect} from 'react-router-dom'
import SignupWindow from "../signup/SignupWindow";

/**
 * @return {null}
 */
export default function LoginModal(props) {
    return <div className="lm-modal">
        <div className="lm-modal-content">
            <div className="lm-close" onClick={props.toggleShowLoginModal}>&times;</div>
            <div className="lm-modal-label">Create a free account to start learning</div>
            <SignupWindow {...props}/>
        </div>
    </div>;
}