import React, {Component} from 'react';
import './Static.css';
import Footer from "../../app/footer/Footer";
import {signupBootcamp} from "../../util/APIUtils";
import Alert from "react-s-alert";
import ReactGA from "react-ga4";

class Mentorship extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        signupBootcamp(this.state.email)
            .then(response => {
                Alert.success(response.message);
            }).catch(error => {
            Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                timeout: 5000
            });
        });
    }

    render() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
        return (
            <div className="home-container">
                <div className="mentorship-header">
                    Online Bootcamp for Coding Interviews
                </div>
                <div className="smaller-header">
                    Double your salary!
                </div>
                <div className="mentorship-first-container">
                    <div className="container sign-up-wrapper">
                        <div>The first free lesson will take place on <b>Wednesday, January 17, 2024 at 8:00 p.m CET(GMT+1)</b>.
                            Sign up and I will send you instructions on how to join!
                        </div>
                        <div className="sign-up-form-wrapper">
                            <form onSubmit={this.handleSubmit} className="footer-newsletter">
                                <input type="email" name="email"
                                       className="form-control" placeholder="Your e-mail"
                                       value={this.state.email} onChange={this.handleInputChange} required/>
                                <div className="footer-button">
                                    <button type="submit" className="btn btn-block btn-primary">Sign up</button>
                                </div>
                            </form>
                        </div>
                        <div className="webinar-details">
                            <div className="webinar-detail">
                                <img src="https://cdn.huro.io/gift-icon.png" className="webinar-icon" alt="Low price"/>
                                <div className="webinar-text">First lesson free</div>
                            </div>
                            <div className="webinar-detail">
                                <img src="https://cdn.huro.io/calendar-icon.png" className="webinar-icon" alt="Low price"/>
                                <div className="webinar-text">Study materials and questions bank access</div>
                            </div>
                            <div className="webinar-detail">
                                <img src="https://cdn.huro.io/chat-icon.png" className="webinar-icon" alt="Low price"/>
                                <div className="webinar-text">Private Slack channel</div>
                            </div>
                            <div className="webinar-detail">
                                <img src="https://cdn.huro.io/save-money.png" className="webinar-icon" alt="Low price"/>
                                <div className="webinar-text">35$ per 1.5h session (or 150 PLN net)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mentorship-second-container">
                    <div className="mentorship-first container">
                        <div className="static-content-box">
                            <p className="mentorship-title">Your mentor</p>
                            <ul className="static-list">
                                <li>Daniel Stradowski, Software Engineer for 9+ years</li>
                                <li>Author of <a className="common-link" href="/course/smash-coding-interview">the
                                    coding interview course</a></li>
                                <li>4 years at Facebook</li>
                                <li>6 months at AWS</li>
                                <li>currently Engineering Manager at Chatdesk
                                </li>
                            </ul>
                        </div>
                        <div className="static-content-filler-photo">
                            <img src={'https://cdn.huro.io/daniel-avatar-high.png'} alt={'Daniel Stradowski'}
                                 className="mentorship-daniel-image"/>
                        </div>
                    </div>
                </div>
                <div className="mentorship-first-container">
                    <div className="mentorship-first container">
                        <div className="static-content-filler">
                            <img src="https://cdn.huro.io/learning-path.png" alt="Goal of the mentorship"/>
                        </div>
                        <div className="static-content-box">
                            <p className="mentorship-title">Goal of the mentorship</p>
                            <ul className="static-list">
                                <li>You’ll be an expert in solving coding interview problems.</li>
                                <li>You’ll always ace system design and architecture questions.</li>
                                <li>You’ll be giving answers which recruiter wants to hear for leadership and soft
                                    skills interview questions.
                                </li>
                                <li>You’ll be motivated and eager to improve as a Software Engineer.</li>
                                <li>You’ll get a high-paying job.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mentorship-second-container">
                    <div className="mentorship-first container">
                        <div className="static-content-box">
                            <p className="mentorship-title">How?</p>
                            <ul className="static-list">
                                <li>You’ll be expected to dedicate 2 hours a day to study for at least 6 months.</li>
                                <li>1.5 hour group sessions with me once a week + homework.</li>
                                <li>You’ll study algorithms, data structures, system design, cloud computing and work on
                                    your Github portfolio.
                                </li>
                                <li>You’ll get an access to study materials and recent real interview questions.</li>
                                <li>We’ll help you look for job offers.</li>
                                <li>We’ll help you with the recruitment process at different companies.</li>
                                <li>We’ll help you negotiate the offer.</li>
                            </ul>
                        </div>
                        <div className="static-content-filler">
                            <img src="https://cdn.huro.io/coding-problem.png" alt="How"/>
                        </div>
                    </div>
                </div>
                <div className="mentorship-first-container">
                    <div className="container">
                        <div className="contact-info-title">Contact information</div>
                        <div className="home-tiles-wrapper">
                            <div className="contact-tile">
                                <div className="reason-icon">
                                    <img src="https://cdn.huro.io/email-icon.png"
                                         alt="Email"/>
                                </div>
                                <div className="reason-title">Email</div>
                                <div className="reason-description">daniel@huro.io
                                </div>
                            </div>
                            <div className="contact-tile">
                                <div className="reason-icon">
                                    <img src="https://cdn.huro.io/linkedin-icon.png"
                                         alt="LinkedIn"/>
                                </div>
                                <div className="reason-title">LinkedIn</div>
                                <div className="reason-description">
                                    <a className="common-link" target="_blank" href="https://www.linkedin.com/in/daniel-stradowski-466699106/">Profile</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Mentorship