import React, {Component} from 'react';
import './Static.css';
import {getAllPayments} from "../../util/APIUtils";
import LoadingIndicator from "../../common/LoadingIndicator";

class PaymentHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: null,
            loading: true,
        };
    }

    componentDidMount() {
        getAllPayments()
            .then(response => {
                this.setState({
                    payments: response,
                    loading: false
                });
            });
    }

    render() {
        return (
            <div className="profile-container profile-info">
                <div className="common-header">
                    Payment History
                </div>
                <table className="payment-table">
                    <thead>
                    <tr>
                        <th>Payment Id</th>
                        <th>Amount</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody className="payment-body">
                    {this.state.loading ? (
                        <tr>
                            <td><LoadingIndicator/></td>
                        </tr>
                    ) : (
                        this.state.payments.map((value, index) => {
                            return <PaymentRow payment={value} index={index} key={index}/>
                        })
                    )
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

function PaymentRow(props) {
    return (
        <tr>
            <td>{props.payment.stripeId}</td>
            <td>{props.payment.amount}$</td>
            <td>{props.payment.creationTime}</td>
        </tr>
    );
}

export default PaymentHistory