import React, {Component} from 'react';
import './Table.css';
import sanitizeHtml from "sanitize-html";

class Table extends Component {
    constructor(props) {
        super(props);

        const {block, contentState} = this.props;
        const {table} = contentState.getEntity(block.getEntityAt(0)).getData();

        this.state = {
            table: table
        };
    }

    static sanitize(html) {
        return sanitizeHtml(html, {
            allowedTags: ['span'],
            allowedAttributes: {
                'span': ['style']
            }
        });
    }

    render() {
        return (
            <table className="customTable">
                <tbody>
                {this.state.table.map(function (row, i) {
                    return (
                        <tr key={i}>
                            {row.map(function (col, j) {
                                return i === 0 ? (<th key={j} dangerouslySetInnerHTML={{
                                    __html: Table.sanitize(col)
                                }} style={{width: (100 / row.length) + '%'}}/>) : (
                                    <td key={j} dangerouslySetInnerHTML={{
                                        __html: Table.sanitize(col)
                                    }}/>);
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

export default Table