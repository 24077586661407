export const SMASH_CODING_INTERVIEW_COURSE = {
    "id": 1,
    "path": "smash-coding-interview",
    "title": "Smash the coding interview",
    "price": 40,
    "discountPercentage": 0,
    "shortDesc": "The best coding interview preparation course. Master the algorithms and data structures needed to ace the coding interview at Meta, Amazon, Netflix, Google, and other big tech companies.",
    "fullDesc": "Want to receive a dream job offer from a top tech company? This course will teach you the problem-solving methods needed to tackle any coding interview question. Stop memorizing a thousand solutions, learn efficiently and land a dream job!\n\nBefore coding a solution, you'll be asked to answer the questions, in the form of an interactive quiz, about the algorithms, data structures you would like to use in your solution, and time, space complexities. It simulates a real interview. We want to present our solution and receive a confirmation from the interviewer that we can go ahead and implement it. It can happen that the interviewer is not satisfied with our solution, and we need to think about the other approach.\n\nThere is a \"thought process\" lesson for each presented problem, which is the most critical part of this course. It describes what steps we should take to find the most optimal solution. The coding questions are carefully selected to present as many unique problem-solving techniques as possible. After learning the thought process, you should be able to solve the given problem and all similar problems.\n\nWe also present a solution to each problem. We describe each final algorithm as a list of steps that are universal for any programming language. Additionally, each solution contains slides for each algorithm step and the code.",
    "imageUrl": "https://cdn.huro.io/smash-the-coding-interview.png",
    "language": "English",
    "sections": [
        {
            "id": 65,
            "name": "Introduction",
            "sectionOrder": 1,
            "lessons": [
                {
                    "id": 69,
                    "lessonOrder": 1,
                    "name": "How to use this course?",
                    "preview": true
                },
                {
                    "id": 67,
                    "lessonOrder": 2,
                    "name": "Time and space complexity",
                    "preview": true
                }
            ]
        },
        {
            "id": 73,
            "name": "Data structures",
            "sectionOrder": 2,
            "lessons": [
                {
                    "id": 68,
                    "lessonOrder": 3,
                    "name": "Graph",
                    "preview": true
                },
                {
                    "id": 66,
                    "lessonOrder": 4,
                    "name": "Tree",
                    "preview": true
                },
                {
                    "id": 28,
                    "lessonOrder": 5,
                    "name": "Heap",
                    "preview": true
                },
                {
                    "id": 20,
                    "lessonOrder": 6,
                    "name": "Trie",
                    "preview": false
                },
                {
                    "id": 30,
                    "lessonOrder": 7,
                    "name": "Queue",
                    "preview": false
                },
                {
                    "id": 23,
                    "lessonOrder": 8,
                    "name": "Stack",
                    "preview": false
                },
                {
                    "id": 9,
                    "lessonOrder": 9,
                    "name": "Map",
                    "preview": false
                },
                {
                    "id": 11,
                    "lessonOrder": 10,
                    "name": "Set",
                    "preview": false
                },
                {
                    "id": 25,
                    "lessonOrder": 11,
                    "name": "Bitmask",
                    "preview": false
                }
            ]
        },
        {
            "id": 74,
            "name": "Algorithms",
            "sectionOrder": 3,
            "lessons": [
                {
                    "id": 18,
                    "lessonOrder": 12,
                    "name": "Brute force",
                    "preview": false
                },
                {
                    "id": 7,
                    "lessonOrder": 13,
                    "name": "Backtracking",
                    "preview": false
                },
                {
                    "id": 8,
                    "lessonOrder": 14,
                    "name": "Binary search",
                    "preview": false
                },
                {
                    "id": 19,
                    "lessonOrder": 15,
                    "name": "Breadth-first search",
                    "preview": true
                },
                {
                    "id": 16,
                    "lessonOrder": 16,
                    "name": "Depth-first search",
                    "preview": false
                },
                {
                    "id": 21,
                    "lessonOrder": 17,
                    "name": "Dynamic programming",
                    "preview": false
                },
                {
                    "id": 24,
                    "lessonOrder": 18,
                    "name": "Sorting",
                    "preview": false
                },
                {
                    "id": 10,
                    "lessonOrder": 19,
                    "name": "Divide and Conquer",
                    "preview": false
                },
                {
                    "id": 17,
                    "lessonOrder": 20,
                    "name": "Two pointers and sliding window",
                    "preview": false
                },
                {
                    "id": 4,
                    "lessonOrder": 21,
                    "name": "Greedy",
                    "preview": false
                },
                {
                    "id": 27,
                    "lessonOrder": 22,
                    "name": "Dijkstra's",
                    "preview": false
                }
            ]
        },
        {
            "id": 3,
            "name": "Data structure questions",
            "sectionOrder": 4,
            "lessons": [
                {
                    "id": 26,
                    "lessonOrder": 23,
                    "name": "Reverse linked list",
                    "preview": true
                },
                {
                    "id": 22,
                    "lessonOrder": 24,
                    "name": "Reverse linked list - thought process",
                    "preview": true
                },
                {
                    "id": 6,
                    "lessonOrder": 25,
                    "name": "Reverse linked list - solution",
                    "preview": true
                },
                {
                    "id": 5,
                    "lessonOrder": 26,
                    "name": "Permutation",
                    "preview": true
                },
                {
                    "id": 29,
                    "lessonOrder": 27,
                    "name": "Permutation - thought process",
                    "preview": true
                },
                {
                    "id": 13,
                    "lessonOrder": 28,
                    "name": "Permutation - solution",
                    "preview": true
                },
                {
                    "id": 14,
                    "lessonOrder": 29,
                    "name": "Lowest common ancestor",
                    "preview": false
                },
                {
                    "id": 12,
                    "lessonOrder": 30,
                    "name": "Lowest common ancestor - thought process",
                    "preview": false
                },
                {
                    "id": 15,
                    "lessonOrder": 31,
                    "name": "Lowest common ancestor - solution",
                    "preview": false
                },
                {
                    "id": 64,
                    "lessonOrder": 32,
                    "name": "Binary search tree",
                    "preview": false
                },
                {
                    "id": 47,
                    "lessonOrder": 33,
                    "name": "Binary search tree - thought process",
                    "preview": false
                },
                {
                    "id": 60,
                    "lessonOrder": 34,
                    "name": "Binary search tree - solution",
                    "preview": false
                },
                {
                    "id": 36,
                    "lessonOrder": 35,
                    "name": "Removing digits",
                    "preview": false
                },
                {
                    "id": 33,
                    "lessonOrder": 36,
                    "name": "Removing digits - thought process",
                    "preview": false
                },
                {
                    "id": 34,
                    "lessonOrder": 37,
                    "name": "Removing digits - solution",
                    "preview": false
                },
                {
                    "id": 59,
                    "lessonOrder": 38,
                    "name": "Three sum",
                    "preview": false
                },
                {
                    "id": 32,
                    "lessonOrder": 39,
                    "name": "Three sum - thought process",
                    "preview": false
                },
                {
                    "id": 44,
                    "lessonOrder": 40,
                    "name": "Three sum - solution",
                    "preview": false
                },
                {
                    "id": 41,
                    "lessonOrder": 41,
                    "name": "Most common elements",
                    "preview": false
                },
                {
                    "id": 45,
                    "lessonOrder": 42,
                    "name": "Most common elements - thought process",
                    "preview": false
                },
                {
                    "id": 39,
                    "lessonOrder": 43,
                    "name": "Most common elements - solution",
                    "preview": false
                },
                {
                    "id": 52,
                    "lessonOrder": 44,
                    "name": "Shorten words",
                    "preview": false
                },
                {
                    "id": 55,
                    "lessonOrder": 45,
                    "name": "Shorten words - thought process",
                    "preview": false
                },
                {
                    "id": 46,
                    "lessonOrder": 46,
                    "name": "Shorten words - solution",
                    "preview": false
                },
                {
                    "id": 57,
                    "lessonOrder": 47,
                    "name": "Bike assignment",
                    "preview": false
                },
                {
                    "id": 43,
                    "lessonOrder": 48,
                    "name": "Bike assignment - thought process",
                    "preview": false
                },
                {
                    "id": 48,
                    "lessonOrder": 49,
                    "name": "Bike assignment - solution",
                    "preview": false
                }
            ]
        },
        {
            "id": 31,
            "name": "Algorithm questions",
            "sectionOrder": 5,
            "lessons": [
                {
                    "id": 40,
                    "lessonOrder": 50,
                    "name": "Go (game)",
                    "preview": true
                },
                {
                    "id": 37,
                    "lessonOrder": 51,
                    "name": "Go (game) - thought process",
                    "preview": true
                },
                {
                    "id": 53,
                    "lessonOrder": 52,
                    "name": "Go (game) - solution",
                    "preview": true
                },
                {
                    "id": 49,
                    "lessonOrder": 53,
                    "name": "Cargo shipping",
                    "preview": true
                },
                {
                    "id": 51,
                    "lessonOrder": 54,
                    "name": "Cargo shipping - thought process",
                    "preview": true
                },
                {
                    "id": 35,
                    "lessonOrder": 55,
                    "name": "Cargo shipping - solution",
                    "preview": true
                },
                {
                    "id": 56,
                    "lessonOrder": 56,
                    "name": "Merge videos",
                    "preview": false
                },
                {
                    "id": 58,
                    "lessonOrder": 57,
                    "name": "Merge videos - thought process",
                    "preview": false
                },
                {
                    "id": 61,
                    "lessonOrder": 58,
                    "name": "Merge videos - solution",
                    "preview": false
                },
                {
                    "id": 50,
                    "lessonOrder": 59,
                    "name": "Change-making problem",
                    "preview": false
                },
                {
                    "id": 42,
                    "lessonOrder": 60,
                    "name": "Change-making problem - thought process",
                    "preview": false
                },
                {
                    "id": 54,
                    "lessonOrder": 61,
                    "name": "Change-making problem - solution",
                    "preview": false
                },
                {
                    "id": 63,
                    "lessonOrder": 62,
                    "name": "Gas stations",
                    "preview": false
                },
                {
                    "id": 38,
                    "lessonOrder": 63,
                    "name": "Gas stations - thought process",
                    "preview": false
                },
                {
                    "id": 62,
                    "lessonOrder": 64,
                    "name": "Gas stations - solution",
                    "preview": false
                },
                {
                    "id": 76,
                    "lessonOrder": 65,
                    "name": "Matrix search",
                    "preview": false
                },
                {
                    "id": 77,
                    "lessonOrder": 66,
                    "name": "Matrix search - thought process",
                    "preview": false
                },
                {
                    "id": 78,
                    "lessonOrder": 67,
                    "name": "Matrix search - solution",
                    "preview": false
                },
                {
                    "id": 79,
                    "lessonOrder": 68,
                    "name": "Knight's dialer",
                    "preview": false
                },
                {
                    "id": 80,
                    "lessonOrder": 69,
                    "name": "Knight's dialer - thought process",
                    "preview": false
                },
                {
                    "id": 81,
                    "lessonOrder": 70,
                    "name": "Knight's dialer - solution",
                    "preview": false
                },
                {
                    "id": 82,
                    "lessonOrder": 71,
                    "name": "Chess queens",
                    "preview": false
                },
                {
                    "id": 83,
                    "lessonOrder": 72,
                    "name": "Chess queens - thought process",
                    "preview": false
                },
                {
                    "id": 84,
                    "lessonOrder": 73,
                    "name": "Chess queens - solution",
                    "preview": false
                },
                {
                    "id": 85,
                    "lessonOrder": 74,
                    "name": "Word chain",
                    "preview": false
                },
                {
                    "id": 86,
                    "lessonOrder": 75,
                    "name": "Word chain - thought process",
                    "preview": false
                },
                {
                    "id": 87,
                    "lessonOrder": 76,
                    "name": "Word chain - solution",
                    "preview": false
                },
                {
                    "id": 88,
                    "lessonOrder": 77,
                    "name": "Splitwise",
                    "preview": false
                },
                {
                    "id": 89,
                    "lessonOrder": 78,
                    "name": "Splitwise - thought process",
                    "preview": false
                },
                {
                    "id": 90,
                    "lessonOrder": 79,
                    "name": "Splitwise - solution",
                    "preview": false
                },
                {
                    "id": 91,
                    "lessonOrder": 80,
                    "name": "Number of bidders",
                    "preview": false
                },
                {
                    "id": 92,
                    "lessonOrder": 81,
                    "name": "Number of bidders - thought process",
                    "preview": false
                },
                {
                    "id": 93,
                    "lessonOrder": 82,
                    "name": "Number of bidders - solution",
                    "preview": false
                }
            ]
        },
        {
            "id": 75,
            "name": "System design",
            "sectionOrder": 6,
            "lessons": [
                {
                    "id": 94,
                    "lessonOrder": 83,
                    "name": "Design image hosting service",
                    "preview": true
                },
                {
                    "id": 95,
                    "lessonOrder": 84,
                    "name": "Onboarding system",
                    "preview": false
                }
            ]
        }
    ],
    "createTime": "2021-02-25T08:14:49.674+0000",
    "updateTime": "2021-02-25T08:14:49.674+0000"
};