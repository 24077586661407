import React, {Component} from 'react';
import './CodeHighlighter.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atomOneLight} from 'react-syntax-highlighter/dist/esm/styles/hljs';

class CodeHighlighter extends Component {
    constructor(props) {
        super(props);

        const {block, contentState} = this.props;
        const {code, title} = contentState.getEntity(block.getEntityAt(0)).getData();

        this.state = {
            code: code,
            title: title
        };
    }

    render() {
        return (
            <React.Fragment>
                <div>{this.state.title}</div>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {this.state.code}
                </SyntaxHighlighter>
            </React.Fragment>
        );
    }
}

export default CodeHighlighter