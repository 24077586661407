import React, {Component} from 'react';
import './Signup.css';
import {Redirect} from 'react-router-dom'
import {AppContext} from "../../context/AppContext";
import SignupWindow from "./SignupWindow";

class Signup extends Component {
    static contextType = AppContext;

    render() {
        const {currentUser} = this.context;
        if (currentUser) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: {from: this.props.location}
                }}/>;
        }

        return (
            <div className="login-container">
                <div className="login-content">
                    <h1 className="login-title">Sign up with Huro</h1>
                    <SignupWindow {...this.props} />
                </div>
            </div>
        );
    }
}

export default Signup