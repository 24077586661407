import React, { Component } from 'react';
import './AccountRecovery.css';
import {accountRecovery} from '../../util/APIUtils';
import Alert from 'react-s-alert';

class AccountRecovery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        accountRecovery(this.state.email)
            .then(response => {
                Alert.success("You should soon receive an email allowing you to reset your password. Please make sure to check your spam folder if you can't find the email.");
                this.props.history.push("/login");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                    timeout: 5000
                });
            });
    }
    
    render() {
        return (
            <div className="login-container">
                <div className="login-content">
                    <div className="account-recovery-text">Forgot your account’s password? Enter your email address and we’ll send you a recovery link.</div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <input type="email" name="email"
                                   className="form-control" placeholder="Email"
                                   value={this.state.email} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-primary">Send recovery email</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default AccountRecovery
