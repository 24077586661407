import React from 'react';
import Loader from 'react-loader-spinner'

export default function LoadingIndicator(props) {
    return (
        <div className="loading-indicator" style={{display: 'block', textAlign: 'center', marginTop: props.marginTop}}>
            <Loader type="Oval" color={props.color} height={props.size} width={props.size} />
        </div>
    );
}

LoadingIndicator.defaultProps = {
    marginTop: "30px",
    size: 60,
    color: "#2098f3"
}