import React, {Component} from 'react';
import './CustomHeader.css';

class CustomHeader extends Component {
    render() {
        return (
            <div id={this.props.block.text.toLowerCase().replaceAll(" ", "-")} className={"custom-header-" + this.props.blockProps.size}>
                {this.props.block.text}
            </div>
        );
    }
}

export default CustomHeader