import React from 'react';
import {
    Route,
    Redirect
  } from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import ReactGA from "react-ga4";

function AdminRoute({ component: Component, ...rest }) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
    return <AppContext.Consumer>{
        (context) => {
            const { currentUser } = context;
            return (
                <Route
                    {...rest}
                    render={props =>
                        currentUser && currentUser.role === 'admin' ? (
                            <Component {...rest} {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {from: props.location.pathname}
                                }}
                            />
                        )
                    }
                />
            )
        }
    }
    </AppContext.Consumer>
}
  
export default AdminRoute