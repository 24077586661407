import React, { Component } from 'react';
import './MyCourses.css';
import LoadingIndicator from "../../common/LoadingIndicator";
import CourseTile from "../tile/CourseTile";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

class MyCourses extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            courses: null,
        };
    }

    componentDidMount() {
        this.setState({
            courses: this.context.currentUser.currentCourses.map(c => c.course),
        });
    }

    render() {
        return (
            <React.Fragment>
                <h1 className="row-header">
                    My courses
                </h1>
                <div className="courses-search">
                    {this.state.courses === null ? (
                            <LoadingIndicator/>
                        ) : (
                            this.state.courses.length === 0 ? (
                                <div className="no-courses-wrapper">
                                    When you enroll in a course, it will appear here. <NavLink to="/courses" className="common-link">Browse now</NavLink>.
                                </div>
                            ) : (
                                this.state.courses.map((value, index) => {
                                    return <div className="my-courses-wrapper" key={index}>
                                        <CourseTile course={value} isClickable={true}/>
                                    </div>
                                })
                            )
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default MyCourses