import React, {Component} from 'react';
import {getCourse} from '../../util/APIUtils';
import './Course.css';
import LoadingIndicator from "../../common/LoadingIndicator";
import Section from "../../common/section/Section";
import CourseTile from "../tile/CourseTile";
import {SMASH_CODING_INTERVIEW_COURSE} from "../../constants/CourseCache";
import LoginModal from "../../user/login_modal/LoginModal";
import {AppContext} from "../../context/AppContext";

class Course extends Component {
    static contextType = AppContext;

    constructor(props, context) {
        super(props);

        const state = this.props.location.state;
        const {coursePath} = this.props.match.params;
        let course = null;
        if (state && state.course) {
            course = state.course;
        }
        else if (coursePath === 'smash-coding-interview') {
            course = process.env.NODE_ENV === 'production' ? SMASH_CODING_INTERVIEW_COURSE : SMASH_CODING_INTERVIEW_COURSE;
        }

        this.state = {
            course: course,
            isDescExpanded: false,
            showLoginModal: state && state.loginModal && !context.currentUser
        };
    }

    componentDidMount() {
        if (!this.state.course) {
            const {coursePath} = this.props.match.params;
            getCourse(coursePath)
                .then(response => {
                    this.setState({
                        course: response,
                    });
                }).catch(error => {
                this.props.history.push("/not-found");
            });
        }

        // clear loginModal after displaying it
        const history = this.props.history;
        if (history.location && history.location.state) {
            const state = { ...history.location.state };
            delete state.loginModal;
            history.replace({ ...history.location, state });
        }
    }

    toggleIsDescExpanded = () => {
        this.setState((prevState) => {
            return {isDescExpanded: !prevState.isDescExpanded}
        });
    };

    toggleShowLoginModal = () => {
        this.setState(prevState => ({
            showLoginModal: !prevState.showLoginModal
        }));
    };

    static splitOnNewLine(text) {
        return text.split('\n').map((item, i) => <p key={i}>{item}</p>);
    }

    formatDescription(text) {
        if (this.state.isDescExpanded) {
            let t = text.split('\n');
            return t.map((item, i) => {
                    return i === t.length - 1 ? (
                        <p key={i}>{item} <span className="common-link" onClick={this.toggleIsDescExpanded}>Read less</span>
                        </p>
                    ) : (
                        <p key={i}>{item}</p>
                    );
                }
            );
        } else {
            let t = (text.substr(0, 300) + "...").split('\n');
            return t.map((item, i) => {
                    return i === t.length - 1 ? (
                        <p key={i}>{item} <span className="common-link" onClick={this.toggleIsDescExpanded}>Read more</span>
                        </p>
                    ) : (
                        <p key={i}>{item}</p>
                    );
                }
            );
        }
    }

    render() {
        const {course, showLoginModal} = this.state;

        if (!course) {
            return <LoadingIndicator/>
        }

        return (
            <React.Fragment>
                <div className="course-first-row">
                    <div className="course-first-column">
                        <h1 className="course-title">
                            {course.title}
                        </h1>
                        <h2 className="course-short-desc">
                            {Course.splitOnNewLine(course.shortDesc)}
                        </h2>
                        <div className="course-author">
                            <img src={'https://cdn.huro.io/daniel-avatar.png'} alt={'Course author'} className="course-author-image"/> Taught by Daniel, Senior Software Engineer, ex-Meta and ex-Amazon
                        </div>
                        <div className="course-label">
                            Description
                        </div>
                        <div className="course-description">
                            {this.formatDescription(course.fullDesc)}
                        </div>
                        <div className="course-label">
                            Contents
                        </div>
                        {course.sections.map((section, index) => {
                            return <Section course={course} section={section} key={index}/>
                        })}
                    </div>
                    <div className="course-second-column">
                        <div className="course-price-box">
                            <CourseTile course={course} isClickable={false}/>
                        </div>
                    </div>
                </div>
                {
                    showLoginModal ? (
                        <LoginModal {...this.props} toggleShowLoginModal = {this.toggleShowLoginModal}/>
                    ) : null
                }
            </React.Fragment>
        );
    }
}

export default Course