import React, {Component} from 'react';
import './CourseTile.css';
import {Link} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import ProgressBar from "../../common/ProgressBar";

class CourseTile extends Component {
    render() {
        if (this.props.isClickable) {
            return (
                <div className="course-tile-link-wrapper">
                    <Link to={{pathname: "course/" + this.props.course.path, state: {course: this.props.course}}}
                          className="course-tile-link"/>
                    <CourseTileChild course={this.props.course} showTitle={this.props.isClickable}/>
                </div>
            )
        } else {
            return <CourseTileChild course={this.props.course} showTitle={this.props.isClickable}/>;
        }
    }
}

class CourseTileChild extends Component {
    static contextType = AppContext;

    render() {
        const {currentUser} = this.context;
        const course = this.props.course;

        const price = course.price - (course.discountPercentage * course.price);
        const discountPercentage = course.discountPercentage * 100;
        const lessonsLength = course.sections.reduce((a, b) => a + b.lessons.length, 0);
        const previewLength = course.sections.reduce((a, b) => {
            return a + b.lessons.filter(l => l.preview).length;
        }, 0);
        let lessonsPercentage = 0;
        let finishedLessons = 0;
        let userLessonsIds = [];
        let currentCourse = null;
        let lastLessonOrderId = course.sections[0].lessons[0].lessonOrder;
        if (currentUser) {
            currentCourse = currentUser.currentCourses.find(c => c.course.id === course.id);

            if (currentCourse) {
                userLessonsIds = currentCourse.finishedLessons.map(l => l.id);
                finishedLessons = course.sections.reduce((a, b) => {
                    return a + b.lessons.filter(l => userLessonsIds.includes(l.id)).length;
                }, 0);
                lessonsPercentage = (finishedLessons * 100) / lessonsLength;

                if (currentCourse.lastLesson) {
                    lastLessonOrderId = currentCourse.lastLesson.lessonOrder;
                }
            }
        }
        return (
            <div className="course-tile-wrapper">
                <img src={course.imageUrl} className="course-image" alt={course.title}/>
                <div className="course-inner-box">
                    {
                        currentCourse ? (
                            <React.Fragment>
                                {
                                    this.props.showTitle ? (
                                        <div>
                                            <div className="course-tile-title">{course.title}</div>
                                        </div>
                                    ) : null
                                }
                                <ProgressBar percentage={lessonsPercentage} style={{zIndex: '-1'}}/>
                                <div className="course-completed">
                                    {finishedLessons}/{lessonsLength} lessons completed
                                </div>
                                <Link to={{
                                    pathname: `/course/${this.props.course.path}/${lastLessonOrderId}`,
                                    course: this.props.course
                                }}
                                      className="btn btn-block btn-primary"
                                      style={{textDecoration: "none"}}>Continue</Link>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="course-price">
                                    ${price} {discountPercentage ? (
                                    <span className="course-full-price">${course.price}</span>
                                ) : null}
                                </div>
                                {discountPercentage ? (
                                    <div className="course-discount">
                                        {discountPercentage.toFixed(0)}% discount
                                    </div>
                                ) : null}
                                <Link to={{pathname: `/buy/${this.props.course.path}`, state: {courses: [this.props.course]}}} className="btn btn-block btn-primary btn-shadow bold"
                                      style={{textDecoration: "none"}}>Buy now</Link>
                                <div className="course-includes-list">
                                    <span className="course-check-icon">&#10004;</span> {lessonsLength} lessons<br/>
                                    <span className="course-check-icon">&#10004;</span> 1 year access<br/>
                                    <span className="course-check-icon">&#10004;</span> {previewLength} free preview lessons
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default CourseTile