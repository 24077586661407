import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import AppHeader from '../common/AppHeader';
import Courses from '../courses/courses/Courses';
import Home from '../home/Home';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import {getCurrentUser} from '../util/APIUtils';
import {ACCESS_TOKEN} from '../constants';
import PrivateRoute from './routes/PrivateRoute';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';
import {AppContext} from "../context/AppContext";
import AccountRecovery from "../user/login/AccountRecovery";
import PasswordChange from "../user/login/PasswordChange";
import Course from "../courses/course/Course";
import MyCourses from "../courses/my_courses/MyCourses";
import FooterRoute from "./routes/FooterRoute";
import Rte from "../editor/rte/Rte";
import AdminRoute from "./routes/AdminRoute";
import ReactGA from 'react-ga4';
import ConfirmEmail from "../user/login/ConfirmEmail";
import BuyCourse from "../courses/buy/BuyCourse";
import FAQ from "../user/static/FAQ";
import TermsOfService from "../user/static/TermsOfService";
import PrivacyPolicy from "../user/static/PrivacyPolicy";
import PaymentSuccessful from "../user/static/PaymentSuccessful";
import PaymentHistory from "../user/static/PaymentHistory";
import LessonRoute from "./routes/LessonRoute";
import ConfirmEmailNewsletter from "../user/login/ConfirmEmailNewsletter";
import PostsList from "../blog/PostsList";
import Post from "../blog/Post";
import Mentorship from "../user/static/Mentorship";

const TRACKING_ID = "G-4T4J06V2K6";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            hideHeader: false,
            loading: true
        };
    }

    loadCurrentlyLoggedInUser() {
        //TODO: make it async, remove loading spinner
        getCurrentUser()
            .then(response => {
                this.setState({
                    currentUser: response,
                    loading: false
                });
            }).catch(error => {
                this.logout();
                this.setState({
                    loading: false
                });
            });
    }

    login = (currentUser) => {
        this.setState({currentUser: currentUser});
    };

    logout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({currentUser: null});
    };

    toggleHeader = () => {
        this.setState(prevState => ({
            hideHeader: !prevState.hideHeader
        }));
    };

    componentDidMount() {
        this.loadCurrentlyLoggedInUser();
    }

    render() {
        ReactGA.initialize(TRACKING_ID);

        if (this.state.loading) {
            return <LoadingIndicator/>
        }

        return (
            <AppContext.Provider value={{currentUser: this.state.currentUser, loginApp: this.login, logoutApp: this.logout, hideHeader: this.state.hideHeader, toggleHeader: this.toggleHeader}}>
                <div className="app">
                    <Route component={AppHeader} />
                    <Switch>
                        <Route exact path="/" render={(props) => (
                            <Home {...props} />)
                        } />
                        <AdminRoute path="/editor" component={Rte}/>
                        <PrivateRoute path="/profile" component={Profile} footer={true}/>
                        <PrivateRoute path="/my-courses" component={MyCourses} footer={true}/>
                        <FooterRoute path="/login" component={Login}/>
                        <FooterRoute path="/signup" component={Signup}/>
                        <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}/>
                        <FooterRoute path="/courses" component={Courses}/>
                        <FooterRoute path="/course/:coursePath" exact component={Course}/>
                        <LessonRoute path="/course/:coursePath/:lessonOrder"/>
                        <FooterRoute path="/account-recovery" component={AccountRecovery}/>
                        <FooterRoute path="/password-change" component={PasswordChange}/>
                        <FooterRoute path="/confirm-email" component={ConfirmEmail}/>
                        <FooterRoute path="/confirm-email-newsletter" component={ConfirmEmailNewsletter}/>
                        <FooterRoute path="/terms-of-service" component={TermsOfService}/>
                        <PrivateRoute path="/payment-successful" component={PaymentSuccessful} footer={true}/>
                        <PrivateRoute path="/payment-history" component={PaymentHistory} footer={true}/>
                        <FooterRoute path="/privacy-policy" component={PrivacyPolicy}/>
                        <FooterRoute path="/faq" component={FAQ}/>
                        {/*<Route exact path="/mentorship" render={(props) => (*/}
                        {/*    <Mentorship {...props} />)*/}
                        {/*} />*/}
                        <FooterRoute path="/blog" exact component={PostsList}/>
                        <FooterRoute path="/blog/:postPath" component={Post}/>
                        <PrivateRoute path="/buy/:coursePath" component={BuyCourse} footer={true} errorMessage={"You need to log in to purchase."}/>
                        <FooterRoute component={NotFound}/>
                    </Switch>
                    <Alert stack={{limit: 3}}
                           timeout={3000}
                           position='top-right' effect='slide' offset={65}/>
                </div>
            </AppContext.Provider>
        );
    }
}

export default App;
