import React, {Component} from 'react';
import './Profile.css';
import {AppContext} from "../../context/AppContext";
import {NavLink} from "react-router-dom";

class Profile extends Component {
    static contextType = AppContext;

    render() {
        const {currentUser} = this.context;
        return (
            <div className="profile-container profile-info">
                <div className="profile-header">
                    Profile
                </div>
                <div className="profile-avatar">
                    {
                        currentUser.imageUrl ? (
                            <img src={currentUser.imageUrl} alt={currentUser.name}/>
                        ) : (
                            <div className="text-avatar">
                                <span>{currentUser.name && currentUser.name[0]}</span>
                            </div>
                        )
                    }
                </div>
                <div className="profile-name">
                    <h2>{currentUser.name}</h2>
                    <p className="profile-email">{currentUser.email}</p>
                </div>
                <div className="profile-header">
                    Payments
                </div>
                <div style={{textAlign: 'left'}}>
                    <NavLink to="/payment-history" className="common-link">Payments history</NavLink>
                </div>
            </div>
        );
    }
}

export default Profile