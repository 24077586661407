import React, {Component} from 'react';
import './Solution.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atomOneLight} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import sanitizeHtml from 'sanitize-html';

class Solution extends Component {
    constructor(props) {
        super(props);

        const {block, contentState} = this.props;
        const {text, code} = contentState.getEntity(block.getEntityAt(0)).getData();

        this.state = {
            text: text,
            code: code,
            isHidden: true
        };
    }

    static sanitize(html) {
        return sanitizeHtml(html, {
            allowedTags: ['sup', 'div'],
            allowedAttributes: {
                'div': ['style']
            }
        });
    }

    toggleIsHidden = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {isHidden: !prevState.isHidden}
        });
    };

    render() {
        return (
            <div className={'solution-wrapper'}>
                <div className={this.state.isHidden ? 'solution-blur' : ''}>
                    <div dangerouslySetInnerHTML={{
                        __html: Solution.sanitize(this.state.text)
                    }}/>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {this.state.code}
                    </SyntaxHighlighter>
                </div>
                <a className={'btn btn-primary btn-shadow solution-button ' + (this.state.isHidden ? '' : 'collapse')} href="#" onClick={this.toggleIsHidden}>Show solution</a>
            </div>
        );
    }
}

export default Solution