import React, {Component} from 'react';
import './Static.css';

class ContactUs extends Component {

    render() {
        return (
            <div className="profile-container profile-info">
                <div className="common-header">
                    Privacy Policy
                </div>
                <div className="terms-content">
                    <p>Last updated: February 13, 2021</p>
                    <p>Huro ("<span className="bold">us</span>", "<span className="bold">we</span>", or "<span className="bold">our</span>") operates the <a className="common-link" href="/">www.huro.io</a> website (the "<span className="bold">Service</span>").</p>
                    <p>This Privacy Policy informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.</p>
                    <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                    <p>We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, capitalized terms used in this Privacy Policy have the same meanings as in our Service Terms of Use.</p>
                    <p className="bold">Information Collection, Use and Retention</p>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address ("Personal Information"). In addition, we track your progress on particular Service courses or programs to help you pick up where you left off and track which courses you have completed.</p>
                    <p>We collect this information for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, servicing your purchase orders, and improving our products and services.</p>
                    <p>We retain personal data during any period in which you have expressed an interest in our products and services, for as long as you have an account with us in order to meet our contractual obligations to you, and for six years after that to identify any issues and resolve any legal proceedings. We may also retain aggregate information beyond this time for research purposes and to help us develop and improve our services. You cannot be identified from aggregate information retained or used for these purposes.</p>
                    <p className="bold">Cookies</p>
                    <p>To make our websites and services work properly, we sometimes use small data files called cookies as well as related tracking technologies. A cookie is a small text file that a website saves on your computer or mobile device when you visit the website.</p>
                    <p className="bold">Types of Cookies we use</p>
                    <p>Essential Cookies</p>
                    <p>Certain cookies are necessary in order for Huro's website to operate properly. For example, we use cookies to authenticate you. When you log on to our platform, authentication cookies are set which let us know who you are during a browsing session. These cookies also facilitate the social media login functionality (e.g., via Facebook, Google, or GitHub) on our website. Essential cookies do not include advertising cookies, which are discussed further below.</p>
                    <p>Personalization/Customization Cookies</p>
                    <p>In some cases, we use cookies to remember the choices you make while browsing the Huro website and to store your preferences for you (e.g., if you edit the order of questions on the questions page). When you return to the same Huro website, the information you previously provided can be retrieved, so you can easily use the Huro features that you customized. This functionality improves user experience and allows us to customize your site experience accordingly.</p>
                    <p>Analytics Cookies</p>
                    <p>Through our service providers, we may use analytics to collect information about your use of the Huro website to create reports and statistics on the performance of the website. Analytics collect information such as your IP address, type of device, operating system, geolocation, referring URLs, time and date of page visits, and which pages you visit. The information allows us to quantify our audience size, see the overall patterns of usage on the platform, help us record any difficulties you have with the website, and show us whether our advertising is effective or not.</p>
                    <p>Advertising Cookies</p>
                    <p>We may use advertising cookies to market Huro products or services to you on third-party websites. For example, if you visit the Huro website, you may then see an advertisement for Huro products or services on certain third-party websites you visit in the future. We sometimes use cookies delivered by third parties to track the performance of our advertisements. For example, these cookies remember which browsers have visited our websites. The information provided to third parties does not include personal information. This process helps us manage and track the effectiveness of our marketing efforts.</p>
                    <p className="bold">Managing your Cookies</p>
                    <p>You have the ability to accept or decline cookies. Most desktop web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. You'll want to refer to your browser's help section to do this since the instructions are usually browser-specific. Mobile devices typically allow you to control cookies through their system settings. Refer to your device manufacturer’s instructions for more information on how to do this. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Huro services.</p>
                    <p className="bold">Log Data</p>
                    <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Log Data").</p>
                    <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
                    <p>When you access the Service by or through a mobile device, this Log Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use and other statistics.</p>
                    <p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase our Service's functionality. These third party service providers have their own privacy policies addressing how they use such information.</p>
                    <p>Please see the section regarding Location Information below regarding the use of your location information and your options.</p>
                    <p className="bold">Location Information</p>
                    <p>We may use and store information about your general location depending on the permissions you have set on your device. We use this information to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time, through your mobile device settings.</p>
                    <p className="bold">Public Forums</p>
                    <p>The Service may make message boards, news groups and/or other public forums available to its users. Any information that is disclosed in these areas becomes public information and you should exercise caution when using these and avoid posting any personal information on these public forums. In addition, if you reach out to any authors directly in connection with any content on the Service, such author will have access to your contact information.</p>
                    <p className="bold">Service Providers</p>
                    <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services and/or to assist us in analyzing how our Service is used.</p>
                    <p>These third parties have access to your Personal Information only to perform specific tasks on our behalf and are obligated not to disclose or use your information for any other purpose. Such third party service providers fall into the following categories: payment processors, hosting service providers, analytics service providers, and marketing/email providers.</p>
                    <p className="bold">Payment processing</p>
                    <p>Payment details you provide will be encrypted using secure sockets layer (SSL) technology before they are submitted to us over the internet. Payments made on the site are made through our payment gateway provider. You will be providing credit or debit card information directly to the operator which operates a secure server to process payment details, encrypting your credit/debit card information and authorizing payment. Information which you supply is not within our control and is subject to the operator's own privacy policy and terms and conditions.</p>
                    <p className="bold">Communications</p>
                    <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>
                    <p className="bold">Compliance With Laws</p>
                    <p>We will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.</p>
                    <p className="bold">Security</p>
                    <p>The security of your Personal Information is important to us, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.</p>
                    <p>However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and we are unable to guarantee the absolute security of the Personal Information we have collected from you.</p>
                    <p className="bold">Links To Other Sites</p>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit.</p>
                    <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <p className="bold">Children's Privacy</p>
                    <p>Our Service is not designed for use by anyone under the age of 13 ("Children").</p>
                    <p>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you learn that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children under age 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
                    <p className="bold">Changes To This Privacy Policy</p>
                    <p>This Privacy Policy is effective as of February 13, 2021 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
                    <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                    <p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>
                    <p className="bold">Contact Us</p>
                    <p>If you have any questions about this Privacy Policy, please contact us at <u>daniel@huro.io</u>.</p>
                </div>
            </div>
        );
    }
}

export default ContactUs