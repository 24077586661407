import React, {Component} from 'react';
import {getCourse, submitPromoCode} from '../../util/APIUtils';
import './BuyCourse.css';
import LoadingIndicator from "../../common/LoadingIndicator";
import {AppContext} from "../../context/AppContext";
import {Link, Redirect} from "react-router-dom";
import {DEFAULT_REDIRECT} from "../../constants";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import Alert from "react-s-alert";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
//
// uncomment below for tests
// const promiseStripe = loadStripe("pk_test_51I1nKlE6tJz6ftcc0L8BnrT8A7LxrjdY3smxaXoZgVlhHDmyXpuZgelK2H1eGmdAJWpSQizrtPJsSggFyobygJAJ00bTRBGmfh");
const promiseStripe = loadStripe("pk_live_51I1nKlE6tJz6ftccfqI1IWEs7c374V5bx2NCXuryULDbJU9OZBjhnM5QNOlnJMC8MVVfpHLdbPibCUB1dT5bF91W00kx8OXuMP");

class BuyCourse extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        const state = this.props.location.state;
        this.state = {
            courses: state ? state.courses : [],
            firstName: "",
            lastName: "",
            promoCode: "",
            discount: 0,
            finalCode: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleApplyCode = this.handleApplyCode.bind(this);
    }

    componentDidMount() {
        if (typeof this.state.courses === "undefined" || this.state.courses.length === 0) {
            const {coursePath} = this.props.match.params;
            getCourse(coursePath)
                .then(response => {
                    this.setState({
                        courses: [response],
                    });
                }).catch(error => {
                this.props.history.push("/not-found");
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleApplyCode(event) {
        event.preventDefault();

        let promoCode = this.state.promoCode;

        if (promoCode) {
            promoCode = promoCode.toUpperCase();
            submitPromoCode(promoCode)
                .then(response => {
                    this.setState({
                        discount: response,
                        finalCode: promoCode,
                    });
                    if (response) {
                        Alert.success("The promo code \'" + promoCode + "\' was successfully applied!");
                    }
                }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                this.setState({
                    discount: 0,
                });
            });
        }
    }

    render() {
        const courses = this.state.courses;
        if (typeof courses === "undefined" || courses.length === 0) {
            return <LoadingIndicator/>
        }

        const {currentUser} = this.context;
        let coursesIds = courses.map(c => c.id);
        let boughtCourses = currentUser.currentCourses.filter(c => coursesIds.includes(c.course.id));
        if (boughtCourses.length > 0) {
            return <Redirect
                to={{
                    pathname: DEFAULT_REDIRECT,
                    state: {from: this.props.location}
                }}/>;
        }

        let fullPrice = 0;
        courses.forEach(c => fullPrice += c.price);

        const price = (1 - this.state.discount) * fullPrice;

        return (
            <div className="buy-container">
                <div className="buy-content-order" style={{textAlign: "left"}}>
                    <label>Order Details</label>
                    <div className="buy-courses-list">
                        {courses.map((course, index) => {
                            return <div key={index}>
                                <div className="order-title">{course.title}</div>
                                <div className="order-price">${course.price.toFixed(2)}</div>
                            </div>
                        })}
                    </div>
                </div>
                <hr className="buy-hr"/>
                <div className="buy-content" style={{textAlign: "left"}}>
                    <div className="buy-total-price">Total price: <span
                        className="buy-price">${price.toFixed(2)} {this.state.discount ? (
                        <span className="buy-course-full-price">${fullPrice.toFixed(2)}</span>
                    ) : null}</span>
                    </div>
                    <div className="buy-small-font" style={{marginBottom: "35px"}}>
                        You'll have access to the selected course{courses.length > 1 ? 's' : ''} for one year.<br/>
                        Your access will be tied to the account you're currently logged in on.
                    </div>
                    <div className="buy-name">
                        <div className="buy-first-name">
                            <div style={{display: "flex"}}>
                                <label>Name</label>
                                <div className="buy-name-tooltip"
                                     data-tooltip="Providing first and last name helps validate your payment as non-fraudulent."/>
                                <div className="buy-filler"/>
                            </div>
                            <input type="text" name="firstName"
                                   className="form-control" placeholder="First name"
                                   value={this.state.firstName} onChange={this.handleInputChange}/>
                        </div>
                        <div className="buy-last-name">
                            <input type="text" name="lastName"
                                   className="form-control" placeholder="Last name"
                                   value={this.state.lastName} onChange={this.handleInputChange}/>
                        </div>
                    </div>
                    <label>Credit or Debit Card</label>
                    <Elements stripe={promiseStripe}>
                        <CheckoutForm coursesIds={coursesIds} promoCode={this.state.finalCode}
                                      name={(this.state.firstName + " " + this.state.lastName).trim()}
                                      courses={this.state.courses}/>
                    </Elements>
                    <form onSubmit={this.handleApplyCode}>
                        <label>Promo Code (optional)</label>
                        <div>
                            <div className="buy-promo-code">
                                <input type="text" name="promoCode"
                                       className="form-control" placeholder="Promo code"
                                       value={this.state.promoCode} onChange={this.handleInputChange}/>
                            </div>
                            <div className="form-item">
                                <button type="submit" className="btn btn-fixed btn-primary btn-shadow">Apply
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="buy-small-font">
                        Got questions? Check out our <Link to="/faq" className="common-link"
                                                           style={{fontWeight: 600}}>FAQ</Link>.<br/>
                        By purchasing you acknowledge that you have read and agreed to our <Link
                        to="/terms-and-conditions" className="common-link" style={{fontWeight: 600}}>Terms and
                        Conditions</Link>.
                    </div>
                </div>
            </div>
        );
    }
}

export default BuyCourse