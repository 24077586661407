import React, {Component} from 'react';
import './Static.css';

class FAQ extends Component {

    render() {
        return (
            <div className="profile-container profile-info">
                <div className="common-header">
                    FAQ
                </div>
                <div className="terms-content">
                    <p className="bold">How can I preview the course before I buy it?</p>
                    <p>Every course has free preview lessons available for you to get an idea of the course content.</p>
                    <p className="bold">For how long do I have access to the course?</p>
                    <p>Individual course subscriptions will give you 1-year access.</p>
                    <p className="bold">After my 1-year access expires, will you automatically renew it and charge me?</p>
                    <p>No. If you want to regain access to a product, you'll have to repurchase that product yourself.</p>
                    <p className="bold">What payment methods do you support?</p>
                    <p>We support all major credit and debit cards.</p>
                    <p className="bold">What is Huro return policy?</p>
                    <p>If you purchased the course within seven days, you could request a refund by sending us an email at daniel@huro.io.</p>
                    <p className="bold">Can my returns be rejected?</p>
                    <p>We have a 7-day return policy so learners can evaluate the course. However, we also have to be protected against fraud. If we determine that you have spent a considerable amount of time on the lessons, we reserve the right to reject your return.</p>
                    <p className="bold">How can I make a business inquiry or bug report?</p>
                    <p>Please email us at <u>daniel@huro.io</u> to contact us with legitimate business inquiries or bug reports. Note that we can't guarantee that we'll get back to you due to the large volume of messages that we receive, but we'll certainly read through your message.</p>
                </div>
            </div>
        );
    }
}

export default FAQ