import React, {Component} from 'react';
import './Static.css';

class ContactUs extends Component {

    render() {
        return (
            <div className="profile-container profile-info">
                <div className="common-header">
                    Terms of Service
                </div>
                <div className="terms-content">
                    <p>Last updated: February 13, 2021</p>
                    <p>Thank you for using the <a className="common-link" href="/">www.huro.io</a> website, and all course materials made available through them (collectively, the “<span className="bold">Service</span>”) operated by Huro. (“<span className="bold">Huro</span>”, “<span className="bold">we</span>”, “<span className="bold">us</span>”, or “<span className="bold">our</span>”). Use of and access to the Service is subject to your compliance with these terms of use (the “<span className="bold">Terms</span>”), so please read these Terms carefully. Huro reserves the right to limit or terminate your access to the Service if you do not comply with these Terms.</p>
                    <p>By accessing and using the Service, you agree to be bound by these Terms. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED IN THE TERMS OF USE, YOU DO NOT HAVE PERMISSION TO USE THE SERVICE IN ANY MANNER. If you are using the Service on behalf of your employer, you represent that you are authorized to accept these Terms on your employer’s behalf.</p>
                    <p>The Services are not intended for use by anyone under the age of 13.</p>
                    <p className="bold">Changes in Terms of Use</p>
                    <p>We reserve the right to modify these Terms from time to time. If we modify these Terms, we will indicate that we have done so on the Service and by changing the effective date above. Continued use of the Service constitutes your acceptance of any modified Terms.</p>
                    <p className="bold">Accounts</p>
                    <p>To sign up for an account to use the Service, you must provide us with your name and email address. It is your responsibility for ensuring that your account information is accurate and up-to-date. You may not share an account with any other person. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                    <p>By creating an account on our Service, you agree that we may send you transactional emails, newsletters, marketing or promotional materials and other information. You may opt out of receiving promotional and marketing communications by following the unsubscribe link or instructions provided in any email we send. You may not opt-out of receiving transactional emails about your purchases or important emails about your use of the Service.</p>
                    <p className="bold">Huro Content</p>
                    <p>Subject to your payment of any applicable fees, Huro grants you a non-transferable, royalty-free license, without the right to sublicense, to access and use any Huro content (“<span className="bold">Content</span>”) you purchase through the Services for a year. The Content is owned by Huro or our authors. Unless the Content has a separate license that grants you other rights, you may only use the Content for your own personal, noncommercial purposes, and you may not (a) reproduce, modify, translate or create any derivative work of the Content; (b) sell, share, rent, lease, loan, provide, distribute or otherwise transfer the Content; (c) circumvent any security measures or attempt to gain access to Content that you have not paid for; or (d) permit or encourage any third party to do any of the foregoing. If we allow you to download any Content, you may only download a single copy for your personal, noncommercial purposes, and you may not share it with third parties.</p>
                    <p>The Content may be provided by third parties. While Huro strives to offer only content of the highest caliber, Huro does not guarantee that the Content is accurate or complete. Your use of or reliance on any Content is at your own risk. If you have any questions about the Content, you are encouraged to contact the author directly.</p>
                    <p>If Content is accompanied by a separate license, the terms of such license shall control over the terms of this section. You are responsible for complying with any license terms that accompany such Content.</p>
                    <p className="bold">Payments</p>
                    <p>In order to purchase an access to the Content, you must provide Huro’s third party payment processor with certain information including, without limitation, your name, your credit card number, the expiration date of your credit card, and your billing address. Our payment processor is only permitted to use such data to process your purchase.</p>
                    <p>By making a purchase with a credit card or other authorized method of payment, you represent and warrant that: (i) you have the legal right to use such credit card(s) or other payment method(s); and (ii) the information you supply to us is true, correct and complete.</p>
                    <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
                    <p>Should automatic billing fail to occur for any reason, Huro will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
                    <p className="bold">Refunds</p>
                    <p>If you are dissatisfied with the Content you purchased, you may apply for a refund within 7 days after your date of purchase. If we grant a refund, your license to use the Content is immediately revoked and you must delete all copies of any Content you have downloaded or cached.</p>
                    <p className="bold">User Conduct and Responsibilities</p>
                    <p>Your use of the Service is subject to all applicable local, state, national and international laws and regulations. We may make sections of the Service available for user comments or message boards. You are responsible for the content that you post on or through the Service, including its legality, reliability, and appropriateness. Without limitation, you agree to not use the Service to make available and transmit any content that, or content that promotes a product or Service that:</p>
                    <ul>
                    <li>is illegal under or otherwise violates any local, state, national or international law or would constitute, encourage or provide instructions for a criminal offense (including, without limitation, the CAN-SPAM Act);</li>
                    <li>violates the rights of any party (including without limitation rights of privacy and publicity);</li>
                    <li>is obscene, lewd, lascivious, violent, or otherwise objectionable;</li>
                    <li>victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
                    <li>displays material that exploits children, or otherwise exploits children under 18 years of age;</li>
                    <li>is false or misleading;</li>
                    <li>infringes any copyright, patent, trademark, trade secret, or other proprietary rights of any party;</li>
                    <li>is unsolicited or unauthorized, including advertising, solicitations, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation;</li>
                    <li>includes private information of a third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;</li>
                    <li>introduces viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                    <li>you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements); or</li>
                    <li>in our sole judgment, is objectionable or which restricts or inhibits any other person from using or enjoying the Service, or which may expose Huro or its users to any harm or liability of any type.</li>
                    </ul>
                    <p>Huro does not control and is not responsible for what users contribute to the Service and is not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content that may be distributed through the Service. Huro is not responsible for the conduct, whether online or offline, of any user of the Service.</p>
                    <p className="bold">Suspension, Termination</p>
                    <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                    <p>If you wish to terminate your account, you may simply discontinue using the Service.</p>
                    <p>All provisions of the Terms which by their nature should survive termination shall survive, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                    <p className="bold">Links to Other Websites</p>
                    <p>The Service or Content may contain (or you may access through the Service or Content) links to other websites controlled by third parties (“<span className="bold">Third-Party Content</span>”). Third Party Content is not investigated, monitored or checked for accuracy, appropriateness, or completeness by Huro, and Huro is not responsible for any Third-Party Content accessed through the Service or content provided by third parties.</p>
                    <p className="bold">Affiliate Program</p>
                    <p>Huro has an affiliate program that enables third parties to earn fees for referring users to the Service from their websites.</p>
                    <p>If you are interested in becoming an affiliate, please contact us via email <u>daniel@huro.io</u></p>
                    <p className="bold">Licenses from Huro; Ownership of Huro Content</p>
                    <p>All content on the Service is the proprietary property of Huro or its authors with all rights reserved. Huro grants you a limited, revocable license to use the Service and all content contained therein in accordance with these Terms. Unless explicitly stated herein, nothing in these Terms shall be construed as conferring any license to Huro’s intellectual property rights, whether by estoppel, implication or otherwise.</p>
                    <p>The Service is protected to the maximum extent permitted by copyright laws and international treaties. You may not decompile or disassemble, reverse engineer or otherwise attempt to discover any source code contained in the Service. Without limiting the foregoing, you agree not to take screenshots of any content on the Service or reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any aspect of the Service.</p>
                    <p>All trademarks and registered trademarks are protected by international trademark laws.</p>
                    <p className="bold">Modifications to Service</p>
                    <p>Huro reserves the right to modify or terminate any or all portions of the Service with or without cause at any time and effective immediately. Huro shall not be liable to you or any third party for termination. Should you object to any modifications to the Service or become dissatisfied with the Service in any way, your only recourse is to immediately discontinue use of the Service.</p>
                    <p className="bold">Notices</p>
                    <p>All notices to a party shall be in writing and shall be made either via e-mail or conventional mail. Huro may broadcast notices or messages through the Service or through the email address you supplied when you registered for the Service to inform you of changes to these Terms, the Service, or other matters of importance. Such broadcasts shall constitute notice to you.</p>
                    <p className="bold">Disclaimers</p>
                    <p>The Service may be temporarily unavailable from time to time for maintenance or other reasons. Huro assumes no responsibility or liability for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, Content.</p>
                    <p>Under no circumstances will Huro be responsible for any loss or damage, including any loss or damage to any Content or personal injury or death, resulting from anyone’s use of the Service, any Content or Third-Party Sites linked to or posted on or through the Service, or any interactions between users of the Service, whether online or offline.</p>
                    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
                    <p>THE SERVICE, AND ALL CONTENT, THIRD-PARTY CONTENT, PRODUCTS AND SERVICES INCLUDED IN THE SITE ARE PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER. HURO DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                    <p>HURO MAKES NO WARRANTY OR REPRESENTATION REGARDING (i) ANY CONTENT, THIRD PARTY CONTENT, PRODUCT OR SERVICE OFFERED OR SOLD THROUGH BY ANY AUTHOR OR THIRD PARTY, (ii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE, (iii) THE CURRENCY, ACCURACY, QUALITY, CONTENT, COMPLETENESS, LEGALITY, OPERABILITY, AVAILABILITY OR RELIABILITY OF ANY INFORMATION OR PRODUCTS OBTAINED THROUGH THE SERVICE, OR (iv) THE DELETION, FAILURE TO STORE, MIS-DELIVERY, OR UNTIMELY DELIVERY OF ANY INFORMATION, PRODUCTS OR MATERIAL. HURO DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE AND/OR SERVICE WILL MEET ANY OF YOUR REQUIREMENTS OR THAT THEY WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE.</p>
                    <p>USE OF THE SERVICE IS AT YOUR SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM YOUR USE OF THE WEBSITE, SERVICE.</p>
                    <p className="bold">Limitation of Liability</p>
                    <p>UNDER NO CIRCUMSTANCES SHALL HURO BE LIABLE TO YOU ON ACCOUNT OF (i) ANY CONTENT, (ii) YOUR USE OR MISUSE OF OR RELIANCE ON THE SERVICE, OR (iii) YOUR INABILITY TO USE THE SERVICE, OR THE INTERRUPTION, SUSPENSION, OR TERMINATION OF THE WEBSITE AND/OR SERVICE (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). SUCH LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES, AND THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST PROFITS, OR LOST DATA ARISING FROM ANY CLAIM RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER HEREOF WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, (EVEN IF HURO OR ITS AUTHORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). IN NO EVENT SHALL THE LIABILITY OF HURO OR ITS OFFICERS, DIRECTORS, AND EMPLOYEES EXCEED $10,000 OR THE IMMEDIATE TWELVE (12) MONTHS OF SERVICE LICENSE FEES PAID BY YOU TO HURO , WHICHEVER IS LOWER. SUCH LIMITATION OF LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
                    <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you.</p>
                    <p className="bold">Indemnity</p>
                    <p>To the fullest extent permitted by law, you agree to indemnify and hold Huro, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney’s fees, arising out of your use of the Service, your conduct in connection with the Service, or any violation of these Terms or of any law or the rights of any third party.</p>
                    <p className="bold">Miscellaneous</p>
                    <p>Waiver and Severability of Terms. The failure of Huro to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. Any waiver of any provision of these Terms will be effective only if in writing and signed by Huro. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p>
                    <p>Choice of Law and Forum. This Agreement shall be governed by and construed in accordance with the laws of the State of Washington, excluding its conflict of law provisions. You and Huro agree to submit to the exclusive jurisdiction of the state and federal courts of Seattle, Washington.</p>
                    <p>Statute of Limitations. You and Huro agree that any cause of action arising out of or related to this Service must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred. Some jurisdictions may prohibit the shortening of the time period in which a cause of action must be brought. In all such jurisdictions, the applicable time period shall be the minimum allowed by law.</p>
                    <p>Promotions. Any contests, sweepstakes or other promotions (collectively, “<span className="bold">Promotions</span>”) made available through the Service may be governed by rules that are separate from these Terms & Conditions. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms and Conditions, the Promotion rules will apply.</p>
                    <p className="bold">Copyright Policy</p>
                    <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (“<span className="bold">Infringement</span>”) of any person or entity.</p>
                    <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to <u>daniel@huro.io</u>, with the subject line: “Copyright Infringement”.</p>
                    <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on your copyright.</p>
                    <p className="bold">Contact Us</p>
                    <p>If you have any questions about these Terms, please contact us at <u>daniel@huro.io</u></p>
                </div>
            </div>
        );
    }
}

export default ContactUs