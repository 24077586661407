import React, {Component} from 'react';
import './CourseCard.css';
import {Link} from "react-router-dom";

class CoursesCard extends Component {

    render() {
        const price = this.props.course.price - (this.props.course.discountPercentage * this.props.course.price);
        const lessonsLength = this.props.course.sections.reduce((a, b) => a + b.lessons.length, 0);
        return <div className="course-card">
            <Link to={{pathname: "course/" + this.props.course.path, state: {course: this.props.course}}}
                  className="course-card-link">
                <img src={this.props.course.imageUrl} className="course-card-image" key={this.props.index}
                     alt={this.props.course.title}/>
                <div className="course-card-tab">
                    <div className="course-card-content">
                        <div className="course-card-title">
                            {this.props.course.title}
                        </div>
                        <div className="course-card-meta">
                            {lessonsLength} lessons
                        </div>
                        <div className="course-card-desc">
                            {this.props.course.shortDesc}
                        </div>
                    </div>
                    <div className="course-card-right">
                        <div className="course-card-price">
                            ${price}
                        </div>
                        {this.props.course.discountPercentage ? (
                            <div className="course-card-full-price">
                                ${this.props.course.price}
                            </div>
                        ) : null}
                    </div>
                </div>
            </Link>
        </div>
    }
}

export default CoursesCard