import React, {Component} from 'react';
import {getAllCourses} from '../../util/APIUtils';
import './Courses.css';
import CoursesCard from "../card/CourseCard";
import LoadingIndicator from "../../common/LoadingIndicator";

class Courses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            courses: null,
            loading: true,
        };
    }

    componentDidMount() {
        getAllCourses()
            .then(response => {
                this.setState({
                    courses: response,
                    loading: false
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <h2 className="row-header">
                    All courses:
                </h2>
                <div className="courses-search">
                    {this.state.loading ? (
                            <LoadingIndicator/>
                        ) : (
                            this.state.courses.map((value, index) => {
                                return <CoursesCard course={value} index={index} key={index}/>
                            })
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default Courses