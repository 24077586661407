import React, {Component} from 'react';
import './Static.css';
import checked from '../../img/icons/checked.png';
import {Link} from "react-router-dom";

class PaymentSuccessful extends Component {

    render() {
        return (
            <div className="profile-container profile-info">
                <div className="payment-checked">
                    <img src={checked}/>
                </div>
                <div className="payment-header">
                    Payment successful!
                </div>
                <div>
                    <Link to="/my-courses">
                        <button type="submit" className="my-courses-button btn btn-primary">My courses
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default PaymentSuccessful