import Quiz from "../block/Quiz";
import CodeHighlighter from "../block/CodeHighlighter";
import Slides from "../block/Slides";
import Table from "../block/Table";
import CustomHeader from "../block/CustomHeader";
import Solution from "../block/Solution";

export function customRenderer(contentBlock) {
    switch(contentBlock.getType()) {
        case 'quiz':
            return {
                component: Quiz,
                editable: false,
            };
        case 'code_block':
            return {
                component: CodeHighlighter,
                editable: false,
            };
        case 'solution':
            return {
                component: Solution,
                editable: false,
            };
        case 'slide_show':
            return {
                component: Slides,
                editable: false,
            };
        case 'table':
            return {
                component: Table,
                editable: false,
            };
        case 'custom-header-2':
            return {
                component: CustomHeader,
                editable: false,
                props: {
                    size: 2,
                },
            };
        case 'custom-header-3':
            return {
                component: CustomHeader,
                editable: false,
                props: {
                    size: 3,
                },
            };
        default:
            break;
    }
};