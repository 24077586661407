import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './AppHeader.css';
import {AppContext} from "../context/AppContext";
import eufoniemLogo from "../img/logo-huro.png";

class AppHeader extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            toggleMenu: false,
        }
    }

    toggleMenu = () => {
        this.setState((prevState, props) => {
            return {toggleMenu: !prevState.toggleMenu}
        });
    };

    render() {
        const { currentUser, logoutApp, hideHeader } = this.context;
        return (
            <header className={'app-header' + (hideHeader ? ' header-hide' : '')}>
                <div className="container">
                    <Link to="/" className="app-branding">
                        <img src={eufoniemLogo} alt="Huro" width="58" height="32"/>
                    </Link>
                    <nav className="app-nav">
                        <div className={"toggle-icon-wrapper " + (this.state.toggleMenu ? "open-menu" : "")} onClick={this.toggleMenu}>
                            <i className="fa fa-bars"/>
                        </div>
                        <ul className={"primary-nav " + (this.state.toggleMenu ? "" : "nav-hide")}>
                            {/*<li>*/}
                            {/*    <NavLink to="/courses" className="common-link">Courses</NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink to="/course/smash-coding-interview" className="common-link">Coding interview course</NavLink>
                            </li>
                            { currentUser && (
                                <li>
                                    <NavLink to="/my-courses" className="common-link">My courses</NavLink>
                                </li>
                            )}
                            {/*<li>*/}
                            {/*    <NavLink to="/mentorship" className="common-link">Mentorship offer</NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink to="/blog" className="common-link">Blog</NavLink>
                            </li>
                        </ul>
                        <ul className={"secondary-nav " + (this.state.toggleMenu ? "" : "nav-hide")}>
                        { currentUser ? (
                            <React.Fragment>
                                <li>
                                    <NavLink to="/profile" className="common-link">Profile</NavLink>
                                </li>
                                <li>
                                    <a onClick={logoutApp} className="common-link">Logout</a>
                                </li>
                            </React.Fragment>
                        ): (
                            <React.Fragment>
                                <li>
                                    <NavLink to={{
                                        pathname: '/login',
                                        state: {from: window.location.pathname}
                                    }} className="common-link">Login</NavLink>
                                </li>
                                <li>
                                    <NavLink to={{
                                        pathname: '/signup',
                                        state: {from: window.location.pathname}
                                    }} className="common-link app-nav-signup">Register</NavLink>
                                </li>
                            </React.Fragment>
                        )}
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}

export default AppHeader;