import React, { Component } from 'react';
import DateFormatter from "../common/DateFormatter";
import './PostAuthor.css';

class PostAuthor extends Component {

    readingTime() {
        const words = this.props.post.content.blocks.reduce((a, b) => a + b.text.trim().split(/\s+/).length, 0);
        const wpm = 225;
        return Math.ceil(words / wpm);
    }

    render() {
        return (
            <div className="post-tile-footer">
                <div className="blue-circle-border">
                    <img src={this.props.post.authorImageUrl} alt={'Course author'} className="post-author-image"/>
                </div>{this.props.post.author}<br/><DateFormatter time={this.props.post.date}/> · {this.readingTime()} min read
            </div>
        );
    }
}

export default PostAuthor;