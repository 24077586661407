import {Component} from "react";
import React from "react";
import {Link, NavLink} from "react-router-dom";
import {signup} from "../../util/APIUtils";
import Alert from "react-s-alert";
import './Signup.css';
import SocialLogin from "../login/SocialLogin";
import {DEFAULT_REDIRECT} from "../../constants";

class SignupWindow extends Component {
    render() {
        return (
            <React.Fragment>
                <SocialLogin {...this.props}/>
                <div className="or-separator">
                    <span className="or-text">OR</span>
                </div>
                <SignupForm {...this.props} />
                <span className="signup-link">Already have an account? <Link
                    to={{
                        pathname: '/login',
                        state: {from: this.props.location.state ? this.props.location.state.from : DEFAULT_REDIRECT}
                    }} className="common-link">Login</Link></span>
            </React.Fragment>
        );
    }
}

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const signUpRequest = Object.assign({}, this.state);

        signup(signUpRequest)
            .then(response => {
                Alert.success(response.message);
                this.props.history.push("/login");
            }).catch(error => {
            Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                timeout: 5000
            });
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    <input type="email" name="email"
                           className="form-control" placeholder="Email"
                           value={this.state.email} onChange={this.handleInputChange} required/>
                </div>
                <div className="form-item">
                    <input type="password" name="password"
                           className="form-control" placeholder="Password"
                           value={this.state.password} onChange={this.handleInputChange} required/>
                </div>
                <div className="form-item">
                    <button type="submit" className="btn btn-block btn-primary">Sign Up</button>
                </div>
            </form>
        );
    }
}

export default SignupWindow