import React, {Component} from 'react';
import './Slides.css';
import SlideShow from "../../lessons/slides/SlideShow";

class Slides extends Component {
    constructor(props) {
        super(props);

        const {block, contentState} = this.props;
        const {images, title, width} = contentState.getEntity(block.getEntityAt(0)).getData();

        this.state = {
            images: images,
            title: title,
            width: width
        };
    }

    render() {
        return (
            <React.Fragment>
                <div>{this.state.title}</div>
                <SlideShow
                    images={this.state.images}
                    withTimestamp={true}
                    showFullscreenIcon={true}
                    style={{maxWidth: this.state.width ? this.state.width : 500}}
                />
            </React.Fragment>
        );
    }
}

export default Slides