import React, {Component} from 'react';
import {
    Link,
} from 'react-router-dom';
import './Footer.css';
import {signupNewsletter} from "../../util/APIUtils";
import Alert from "react-s-alert";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        signupNewsletter(this.state.email)
            .then(response => {
                Alert.success(response.message);
            }).catch(error => {
            Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                timeout: 5000
            });
        });
    }

    render() {
        return (
            <footer>
                <div className="footer-container">
                    <div className="footer-first-row">
                        <div className="footer-first-column-wrapper">
                            <div className="footer-first-column">
                                <div className="footer-link-heading">
                                    Newsletter
                                </div>
                                <div className="footer-newsletter-title">
                                    Get updates with the free materials, discounts and more.
                                </div>
                                <form onSubmit={this.handleSubmit} className="footer-newsletter">
                                    <input type="email" name="email"
                                           className="form-control" placeholder="Your e-mail"
                                           value={this.state.email} onChange={this.handleInputChange} required/>
                                    <div className="footer-button">
                                        <button type="submit" className="btn btn-block btn-primary">Sign up</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="footer-second-column-wrapper">
                            <div className="footer-second-column">
                                <div className="footer-link-heading">
                                    More
                                </div>
                                <div className="footer-link-wrapper">
                                    <Link className="footer-link" to="/faq">FAQ</Link>
                                </div>
                                <div className="footer-link-wrapper">
                                    <Link className="footer-link" to="/terms-of-service">Terms of service</Link>
                                </div>
                                <div className="footer-link-wrapper">
                                    <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer-third-column-wrapper">
                            <div className="footer-third-column">
                                <div className="footer-link-heading">
                                    Follow us
                                </div>
                                <div>
                                    <a className="footer-social" title="Subscribe to Huro on Youtube"
                                       href="https://www.youtube.com/channel/UCcI8kLXs2KvW3AprHnT_GdQ?sub_confirmation=1">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube"
                                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path fill="currentColor"
                                                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        Copyright © 2021 Huro. All rights reserved.
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
