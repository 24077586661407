import React from 'react';
import {Route,} from "react-router-dom";
import FooterWrapper from "../footer/FooterWrapper";
import ReactGA from "react-ga4";

function FooterRoute({ component: Component, ...rest }) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
    return <Route
        {...rest}
        render={props =>
            <FooterWrapper>
                <Component {...rest} {...props} />
            </FooterWrapper>
        }
    />
}
  
export default FooterRoute