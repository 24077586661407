import React, { Component } from 'react';

class DateFormatter extends Component {
    render() {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(this.props.time * 1000);
        const day = d.getDate();
        const month = months[d.getMonth()];
        const year = d.getFullYear() - 2000

        return (
            <React.Fragment>
                {month + " " + day + " '" + year}
            </React.Fragment>
        );
    }
}

export default DateFormatter;