import {Component} from "react";
import Alert from "react-s-alert";
import {FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL} from "../../constants";
import React from "react";
import './Login.css';

class SocialLogin extends Component {
    render() {
        let urlState = '';
        if(this.props.location.state) {
            urlState = '&state=' + this.props.location.state.from;
            const errorMessage = this.props.location.state.errorMessage;
            if(errorMessage) {
                Alert.error(errorMessage, {
                    timeout: 5000
                });
            }
        }
        return (
            <div className="social-login">
                <a className="btn btn-block social-btn google text-common-link" href={GOOGLE_AUTH_URL + urlState}>
                    <img src={'https://cdn.huro.io/google-logo.png'} alt="Google" /> Continue with Google</a>
                {/*<a className="btn btn-block social-btn facebook text-common-link" href={FACEBOOK_AUTH_URL + urlState}>*/}
                {/*    <img src={'https://cdn.huro.io/fb-logo.png'} alt="Facebook" /> Continue with Facebook</a>*/}
                {/*<a className="btn btn-block social-btn github text-common-link" href={GITHUB_AUTH_URL}>*/}
                {/*    <img src={githubLogo} alt="Github" /> Log in with Github</a>*/}
            </div>
        );
    }
}

export default SocialLogin