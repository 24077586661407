import React, {Component} from 'react';
import './Quiz.css';
import sanitizeHtml from 'sanitize-html';
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import {atomOneLight} from "react-syntax-highlighter/dist/esm/styles/hljs";

const INCORRECT_SOLUTION_MESSAGE = 'Incorrect solution. Try again.';

class Quiz extends Component {
    constructor(props) {
        super(props);

        const {block, contentState} = this.props;
        const entity = contentState.getEntity(block.getEntityAt(0));
        const {title, questions, answers} = entity.getData();
        const type = entity.getType();

        this.state = {
            answerIndex: 0,
            title: title,
            type: type,
            questions: questions,
            answers: new Array(questions.length).fill(null),
            correctAnswers: answers,
            message: null,
            messageColor: 'red'
        };
        this.setAnswers = this.setAnswers.bind(this);
    }

    validateAnswer() {
        for (let i = 0; i < this.state.correctAnswers.length; i++) {
            if ('' + this.state.answers === '' + this.state.correctAnswers[i].answer) {
                return {
                    message: this.state.correctAnswers[i].message,
                    messageColor: this.state.correctAnswers[i].isCorrect ? "#52c41a" : "orange"
                };
            }
        }
        return {
            message: INCORRECT_SOLUTION_MESSAGE,
            messageColor: "red"
        };
    }

    handleSubmit(event) {
        event.target.blur();

        if (this.isFilled(this.state.answers)) {
            const answer = this.validateAnswer();

            this.setState({
                message: answer.message,
                messageColor: answer.messageColor
            });
        } else {
            this.setState({
                message: "You must answer all questions.",
                messageColor: 'red'
            });
        }
    }

    isFilled(answer) {
        return !answer.some(i => i === null);
    }

    getCorrectAnswers(answerIndex, questionIndex) {
        let correctAnswers = this.state.correctAnswers[answerIndex].answer[questionIndex];
        if (Number.isInteger(correctAnswers)) {
            correctAnswers = [correctAnswers];
        }
        return correctAnswers;
    }

    setAnswers(isMultiSelect, qIndex, value) {
        this.setState(prevState => {
            let isDeselect = false;
            if (isMultiSelect && prevState.answers[qIndex]) {
                if (prevState.answers[qIndex].includes(value)) {
                    isDeselect = true;
                    prevState.answers[qIndex] = prevState.answers[qIndex].filter(i => i !== value);
                } else {
                    prevState.answers[qIndex] = [...prevState.answers[qIndex], value].sort((a, b) => a - b);
                }
            } else {
                prevState.answers[qIndex] = [value];
            }

            let answerIndex = prevState.answerIndex;
            if (this.state.type === 'QUIZ_INSTANT_FEEDBACK') {
                if (isDeselect) {
                    for (let i = 0; i < this.state.correctAnswers.length; i++) {
                        //check if other selections for this question are valid
                        if ('' + prevState.answers[qIndex] === '' + this.getCorrectAnswers(i, qIndex)) {
                            answerIndex = i;
                        }
                    }
                } else if (!this.getCorrectAnswers(this.state.answerIndex, qIndex).includes(value)) {
                    for (let i = 0; i < this.state.correctAnswers.length; i++) {
                        let correctAnswer = this.getCorrectAnswers(i, qIndex);
                        if (correctAnswer.includes(value)) {
                            answerIndex = i;

                            //checking if that's the most optimal answer
                            let currentAnswer = this.state.correctAnswers[answerIndex].answer;
                            for (let j = 0; j < currentAnswer.length; j++) {
                                if (prevState.answers[j] !== null && ('' + prevState.answers[j] !== '' + this.getCorrectAnswers(answerIndex, j))) {
                                    //at least one answer doesn't match, continue the search for better answer
                                    break;
                                }
                                if (j === currentAnswer.length - 1) {
                                    //all answers match, that's the best answer
                                    i = this.state.correctAnswers.length;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (this.isFilled(prevState.answers)) {
                    const answer = this.validateAnswer();

                    return {
                        answerIndex: answerIndex,
                        answers: prevState.answers,
                        message: answer.message,
                        messageColor: answer.messageColor
                    };
                }
            }

            return {
                answerIndex: answerIndex,
                answers: prevState.answers,
                message: null,
            };
        });
    }

    render() {
        return (
            <div>
                <div>{this.state.title}</div>
                {
                    this.state.questions.map((question, index) => {
                        const correctAnswers = this.state.type === 'QUIZ_INSTANT_FEEDBACK' ? this.getCorrectAnswers(this.state.answerIndex, index) : [];
                        return <Question data={question} index={index} key={index} setAnswers={this.setAnswers}
                                         answers={this.state.answers[index]}
                                         correctAnswers={correctAnswers}
                                         isWrong={this.state.message === INCORRECT_SOLUTION_MESSAGE}
                                         type={this.state.type}/>
                    })
                }
                <div className="quiz-btn-wrapper">
                    {
                        this.state.type === 'QUIZ_INSTANT_FEEDBACK' ? null : (
                            <button className="btn btn-fixed btn-primary" onClick={(e) => {
                                this.handleSubmit(e)
                            }}>
                                Submit
                            </button>
                        )
                    }
                    <div className="quiz-msg-wrapper" style={{color: this.state.messageColor}}>
                        {
                            this.state.message ? (
                                <div>
                                    {this.state.message}
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class Question extends Component {
    constructor(props) {
        super(props);

        this.setAnswers = this.setAnswers.bind(this);
    }

    setAnswers(isMultiSelect, index, value) {
        this.props.setAnswers(isMultiSelect, index, value);
    }

    static sanitize(html) {
        return sanitizeHtml(html, {
            allowedTags: ['sup', 'div'],
            allowedAttributes: {
                'div': ['style']
            }
        });
    }

    render() {
        const isMultiSelect = this.props.data.selectType === 'MULTIPLE';
        let isCorrect = false;

        return (
            <div>
                <div className="question-wrapper">
                    {this.props.data.question}
                </div>
                {
                    this.props.data.code ? (
                        <SyntaxHighlighter language="java" style={atomOneLight}>
                            {this.props.data.code}
                        </SyntaxHighlighter>
                    ) : null
                }
                <form className="answers-wrapper">
                    {
                        this.props.data.answers.map((answer, index) => {
                            let answerValidationStyle = '';
                            if (this.props.type === 'QUIZ_INSTANT_FEEDBACK') {
                                const isGreen = this.props.correctAnswers.includes(index);
                                answerValidationStyle = 'answer-checkbox ' + (isGreen ? 'answer-t' : 'answer-n');
                                if (isGreen && !isCorrect && this.props.answers && this.props.answers.includes(index)) {
                                    isCorrect = true;
                                }
                                if (isMultiSelect && this.props.answers && this.props.isWrong) {
                                    const correctSelected = this.props.answers.filter(value => this.props.correctAnswers.includes(value));
                                    if (correctSelected.includes(index)) {
                                        const allCorrect = this.props.correctAnswers.length;
                                        answer = answer.concat('<div style="float:right;margin-right: 3px;">' + correctSelected.length + '/' + allCorrect + '</div>');
                                    }
                                }
                            }
                            return (
                                <div key={index}
                                     className={"answer-wrapper " + answerValidationStyle}>
                                    <input id={this.props.index + ' ' + index} name="radio"
                                           type={isMultiSelect ? "checkbox" : "radio"}
                                           onChange={() => this.setAnswers(isMultiSelect, this.props.index, index)}/>
                                    <label htmlFor={this.props.index + ' ' + index} dangerouslySetInnerHTML={{
                                        __html: Question.sanitize(answer)
                                    }}/>
                                </div>
                            )
                        })
                    }
                </form>
                {
                    this.props.data.correctMessage ? (
                        <div className={'answer-tooltip'}>
                            {isCorrect ? (
                                <div dangerouslySetInnerHTML={{
                                    __html: Question.sanitize(this.props.data.correctMessage)
                                }}/>
                            ) : ''}
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

export default Quiz