import React from 'react';
import {
    Route,
    Redirect
  } from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import FooterWrapper from "../footer/FooterWrapper";
import ReactGA from "react-ga4";

function PrivateRoute({ component: Component, ...rest }) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });

    return <AppContext.Consumer>{
        (context) => {
            const { currentUser } = context;
            const { footer, errorMessage } = rest;
            return (
                <Route
                    {...rest}
                    render={props =>
                        currentUser ? (
                            footer ? (
                                    <FooterWrapper>
                                        <Component {...rest} {...props} />
                                    </FooterWrapper>
                                ) : (
                                    <Component {...rest} {...props} />
                                )
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {
                                        from: props.location.pathname,
                                        errorMessage: errorMessage
                                    }
                                }}
                            />
                        )
                    }
                />
            )
        }
    }
    </AppContext.Consumer>
}
  
export default PrivateRoute