import React, {Component} from 'react';
import './Rte.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {customRenderer} from "../util/RendererUtils";

class Rte extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editorStateFrom: EditorState.createEmpty(),
            editorStateTo: EditorState.createEmpty(),
            copiedMessage: '',
            isStripped: true,
        };
    }

    onEditorStateChangeFrom = (editorState) => {
        this.setState({
            editorStateFrom: editorState,
            copiedMessage: ''
        });
    };

    onEditorStateChangeTo = (event) => {
        if(this.isJSON(event.target.value)) {
            this.setState({
                editorStateTo: EditorState.createWithContent(convertFromRaw(JSON.parse(event.target.value))),
            });
        }
    };

    isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(document.getElementById('jsonOutput').textContent);
        this.setState({
            copiedMessage: ' Copied!',
        });
    };

    toggleChange = () => {
        this.setState({
            isStripped: !this.state.isStripped,
        });
    };

    render() {
        const { editorStateFrom, editorStateTo } = this.state;
        return (
            <div className="container">
                <h2 className="row-header">
                    From text to JSON:
                </h2>
                <input type="checkbox"
                       checked={this.state.isStripped}
                       onChange={this.toggleChange}
                /> Strip pasted styles
                <Editor
                    stripPastedStyles={this.state.isStripped}
                    editorState={editorStateFrom}
                    editorClassName="rte-editor"
                    onEditorStateChange={this.onEditorStateChangeFrom}
                />
                <p/>
                <div className="rte-content rte-textarea" style={{paddingBottom: '20px'}}>
                    <pre id="jsonOutput">{JSON.stringify(convertToRaw(editorStateFrom.getCurrentContent()), null, 2)}</pre>
                </div>
                <div>
                    <button onClick={this.copyToClipboard}>Copy to clipboard</button>
                    {this.state.copiedMessage}
                </div>
                <h2 className="row-header">
                    From JSON to text:
                </h2>
                <textarea className="rte-content rte-textarea" onChange={this.onEditorStateChangeTo} />
                <p/>
                <Editor
                    editorState={editorStateTo}
                    readOnly={true}
                    toolbarHidden={true}
                    customBlockRenderFunc={customRenderer}
                    editorClassName="rte-reader"
                />
                <p/>
            </div>
        );
    }
}

export default Rte