import React, { Component } from 'react';
import {getUrlParameter, passwordChange} from '../../util/APIUtils';
import Alert from 'react-s-alert';

class PasswordChange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confPassword: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if(this.state.password !== this.state.confPassword) {
            Alert.error('Passwords do not match.');
            this.setState({
                password: '',
                confPassword: ''
            });
        } else {
            passwordChange({
                password: this.state.password,
                token: getUrlParameter('upn')
            })
                .then(response => {
                    Alert.success("Password successfully changed.");
                    this.props.history.push("/login");
                }).catch(error => {
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                        timeout: 5000
                    });
                    this.setState({
                        password: '',
                        confPassword: ''
                    });
            });
        }
    }
    
    render() {
        return (
            <div className="login-container">
                <div className="login-content">
                    <h1 className="login-title">Reset Password</h1>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <input type="password" name="password"
                                   className="form-control" placeholder="Password"
                                   value={this.state.password} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <input type="password" name="confPassword"
                                   className="form-control" placeholder="Confirm Password"
                                   value={this.state.confPassword} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PasswordChange
