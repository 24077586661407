import React, {Component} from 'react';
import './PostCard.css';
import {Link} from "react-router-dom";
import PostAuthor from "./PostAuthor";

class PostCard extends Component {

    readingTime() {
        const words = this.props.post.content.blocks.reduce((a, b) => a + b.text.trim().split(/\s+/).length, 0);
        const wpm = 225;
        return Math.ceil(words / wpm);
    }

    render() {
        return (
            <div className="post-tile">
                <div className="course-tile-link-wrapper">
                    <Link to={{pathname: "blog/" + this.props.post.path}} className="course-tile-link"/>
                    <img src={this.props.post.imageUrl} className="post-card-image" alt={this.props.post.title}/>
                    <div className="post-tile-box">
                        <div className="post-tile-title word-break">{this.props.post.title}</div>
                        <div className="post-tile-desc word-break">{this.props.post.shortDesc}</div>
                        <PostAuthor post={this.props.post}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostCard