import React, { Component } from 'react';
import './Section.css';
import {Link} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

class Section extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState(prevState => ({
            collapse: !prevState.collapse
        }));
    };

    render() {
        const {currentUser} = this.context;
        let userLessonsIds = [];
        let currentCourse = null;
        if(currentUser) {
            currentCourse = currentUser.currentCourses.find(c => c.course.id === this.props.course.id);
            if(currentCourse) {
                userLessonsIds = currentCourse.finishedLessons.map(l => l.id);
            }
        }
        return (
            <React.Fragment>
                <div className="section-wrapper" onClick={this.toggleCollapse}>{this.state.collapse ? '+' : '–'} {this.props.section.name}</div>
                <div className={'course-lessons-wrapper ' + (this.state.collapse ? 'collapse' : null)}>
                    {this.props.section.lessons.map((lesson, index) => {
                        const isCompleted = userLessonsIds.includes(lesson.id);
                        let fillColor = "white";
                        let strokeColor = "#979797";
                        let lessonColor = "grey";
                        if(isCompleted) {
                            fillColor = "#2098f3";
                            strokeColor = "#2098f3";
                            lessonColor = "blue";
                        }
                        if(index === this.props.section.lessons.length - 1) {
                            lessonColor = "last";
                        }
                        return <div key={index} className={"course-lesson-wrapper course-lesson-wrapper-" + lessonColor}>
                            <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" className="section-point">
                                <g stroke="none" strokeWidth="1" fill={fillColor} fillRule="evenodd">
                                    <g transform="translate(-135.000000, -351.000000)" stroke={strokeColor}>
                                        <circle cx="140.5" cy="357" r="5"></circle>
                                    </g>
                                </g>
                            </svg>
                            <div className="section-lesson-label">
                                <Link to={{pathname: `/course/${this.props.course.path}/${lesson.lessonOrder}`, course: this.props.course}} className={this.props.lesson && (lesson.id === this.props.lesson.id) ? 'section-selected' : 'text-common-link'}>
                                    {lesson.name}
                                    {
                                        lesson.preview && !currentCourse ? (
                                            <span className="section-lesson-preview">&nbsp;FREE</span>
                                        ) : null
                                    }
                                </Link>
                            </div>
                        </div>
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default Section