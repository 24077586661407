import React, { Component } from 'react';
import { getUrlParameter } from '../../util/APIUtils';
import { Redirect } from 'react-router-dom'
import {DEFAULT_REDIRECT} from "../../constants";
import ReactGA from "react-ga4";

class OAuth2RedirectHandler extends Component {
    render() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
        const error = getUrlParameter('error');

        if(error) {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: error
                }
            }}/>;
        } else {
            const redirectUrl = getUrlParameter('state');
            return <Redirect to={{
                pathname: redirectUrl ? redirectUrl : DEFAULT_REDIRECT,
                state: { from: this.props.location }
            }}/>;
        }
    }
}

export default OAuth2RedirectHandler;