import React, {useState} from 'react';
import {Redirect, Route} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import {SMASH_CODING_INTERVIEW_COURSE} from "../../constants/CourseCache";
import Lesson from "../../lessons/lesson/Lesson";
import ReactGA from "react-ga4";

function LessonRoute({component: Component, ...rest}) {
    const [course, setCourse] = useState(SMASH_CODING_INTERVIEW_COURSE);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });


    return <AppContext.Consumer>{
        (context) => {
            const {currentUser} = context;
            return (
                <Route
                    {...rest}
                    render={props => {
                        let canViewLesson = currentUser;
                        if (!canViewLesson && course) {
                            const lessonOrderToFind = parseInt(props.match.params.lessonOrder)
                            const lesson = course.sections.flatMap(section => section.lessons)
                                .find(lesson => lesson.lessonOrder === lessonOrderToFind);
                            canViewLesson = lesson && lesson.preview;
                        }
                        return canViewLesson ? (
                            <Lesson key={props.match.params.lessonOrder} {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/course/' + props.match.params.coursePath,
                                    state: {
                                        from: props.location.pathname,
                                        loginModal: true,
                                    }
                                }}
                            />
                        )
                    }

                    }
                />
            )
        }
    }
    </AppContext.Consumer>
}

export default LessonRoute