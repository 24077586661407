import React, {Component} from 'react';
import './Home.css';
import {Link} from "react-router-dom";
import fbLogo from '../img/companies/facebook.svg';
import amazon from '../img/companies/amazon.svg';
import netflix from '../img/companies/netflix.svg';
import google from '../img/companies/google.svg';
import microsoft from '../img/companies/microsoft.svg';
import Footer from "../app/footer/Footer";

class Home extends Component {
    render() {
        return (
            <div className="home-container">
                <div className="home-first-wrapper">
                    <div className="home-first container">
                        <div className="home-left-panel">
                            <h1 className="home-first-title">The best way to prepare for a technical interview.</h1>
                            <div className="home-first-subtitle">The carefully designed course contains all the
                                essential information
                                needed to
                                smash the coding interview.
                            </div>
                            <div className="home-first-button-wrapper">
                                <Link to={{pathname: `/course/smash-coding-interview/1`}}
                                      className="btn btn-fixed btn-primary btn-shadow home-button">Try for free</Link>
                            </div>
                        </div>
                        <div className="home-right-panel">
                            <img src={'https://cdn.huro.io/course-page-sample.png'}
                                 alt="Smash the coding interview"/>
                        </div>
                    </div>
                </div>
                <div className="home-second-wrapper">
                    <div className="container">
                        <div className="home-second-title">Why Huro?</div>
                        <div className="home-tiles-wrapper">
                            <ReasonTile title={'An authentic interview experience'}
                                        description={'Simulated interview questions. We\'ll check if you have a correct solution in mind before coding it.'}
                                        image={'https://cdn.huro.io/job-interview.png'}/>
                            <ReasonTile title={'Coding problem solving techniques'}
                                        description={'No memorizing of the solutions. Learn the patterns to solve any problem.'}
                                        image={'https://cdn.huro.io/coding-problem.png'}/>
                            <ReasonTile title={'Visualize the solution'}
                                        description={'A step by step visual description for every solution to aid understanding and learning.'}
                                        image={'https://cdn.huro.io/graph-nodes.png'}/>
                            <ReasonTile title={'Clear learning path'}
                                        description={'We\'ll teach you every popular algorithm and data structure that can appear at the coding interview.'}
                                        image={'https://cdn.huro.io/learning-path.png'}/>
                            <ReasonTile title={'Save time'}
                                        description={'Videos are holding you back. Reading is faster than watching.'}
                                        image={'https://cdn.huro.io/back-in-time.png'}/>
                            <ReasonTile title={'Low price'}
                                        description={'The best price for top-quality coding interview preparation platform access.'}
                                        image={'https://cdn.huro.io/save-money.png'}/>
                        </div>
                    </div>
                </div>
                <div className="home-third-wrapper">
                    <div className="container">
                        <div className="home-third-title">
                            Trusted by engineers working in companies like
                        </div>
                        <div className="home-companies-wrapper">
                            <img src={fbLogo} className="home-company-logo"
                                 style={{height: 28, marginTop: 36, marginBottom: 36}} alt={"Facebook"}/>
                            <img src={amazon} className="home-company-logo" alt="Amazon"/>
                            <img src={netflix} className="home-company-logo" alt="Netflix"/>
                            <img src={google} className="home-company-logo" alt="Google"/>
                            <img src={microsoft} className="home-company-logo" alt="Microsoft"/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

const ReasonTile = ({title, description, image}) => {
    return (
        <div className="reason-tile">
            <div className="reason-icon">
                <img src={image} alt={title}/>
            </div>
            <div className="reason-title">
                {title}
            </div>
            <div className="reason-description">
                {description}
            </div>
        </div>
    )
};

export default Home;