export default {
  ROOT: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: '100%',
    lineHeight: '1px',
    margin: 'auto',
    outline: 0,
    padding: 0,
    position: 'relative',
    textDecoration: 'none',
    minWidth: 250,
    maxWidth: 600,
  },
  IMAGE: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    width: '100%',
  },
  BUTTON: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0 20px',
    padding: 0,
  },
  BAR: {
    backgroundColor: '#505763',
    height: 30,
    lineHeight: '30px',
    margin: 'auto',
    overflow: 'auto',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  PAGE_VIEW: {
    color: '#fff',
  },
  ARROW_BUTTON: {
    backgroundColor: 'transparent',
    height: 15,
    width: 15,
  },
  PREV_ON_CONTENT: {
    display: 'block',
    width: '40%',
    height: '95%',
    top: 0,
    left: 0,
    position: 'absolute',
    cursor: 'w-resize',
  },
  NEXT_ON_CONTENT: {
    display: 'block',
    width: '40%',
    height: '95%',
    top: 0,
    right: 0,
    position: 'absolute',
    cursor: 'e-resize',
  },
  PROGRESS_BAR: {
    backgroundColor: '#e8e9eb',
    height: 10,
    marginTop: -6,
    position: 'relative',
    width: '100%',
  },
};
