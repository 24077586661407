import React, {Component} from 'react';
import {confirmEmailNewsletter, getUrlParameter} from '../../util/APIUtils';
import Alert from 'react-s-alert';
import LoadingIndicator from "../../common/LoadingIndicator";

class ConfirmEmailNewsletter extends Component {

    componentDidMount() {
        confirmEmailNewsletter(getUrlParameter('ctn'))
            .then(response => {
                Alert.success(response.message, {
                    timeout: 5000
                });
            })
            .catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.props.history.push("/");
            });
    }

    render() {
        return (
            <div className="login-container">
                <div className="login-content">
                    Verifying email
                    <LoadingIndicator/>
                </div>
            </div>
        );
    }
}

export default ConfirmEmailNewsletter
