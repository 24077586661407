import * as React from 'react';
import {onlyUpdateForKeys} from 'recompose';

export default onlyUpdateForKeys(['progress'])(function({
  style,
  onClick,
  onMouseMove,
  onMouseLeave,
  progress,
}) {
  return (
    <div
      style={style}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          backgroundColor: '#40a9ff',
          height: '100%',
          width: `${progress}%`,
        }}
      />
    </div>
  );
});
