import React, {useState, useEffect, useContext} from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import './CheckoutForm.css';
import {createPaymentIntent} from "../../util/APIUtils";
import {AppContext} from "../../context/AppContext";
import LoadingIndicator from "../../common/LoadingIndicator";
import { Redirect } from 'react-router-dom'

export default function CheckoutForm(props) {
    const {currentUser} = useContext(AppContext);
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        const createPaymentIntentRequest = Object.assign({}, {coursesIds: props.coursesIds, promoCode: props.promoCode});

        createPaymentIntent(createPaymentIntentRequest)
            .then(s => {
                setClientSecret(s);
            });
    }, [props.promoCode]);
    const cardStyle = {
        style: {
            base: {
                fontFamily: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#cccccc"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            receipt_email: currentUser.email,
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: currentUser.email,
                    name: props.name || null,
                },
            }
        });
        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            addCurrentCourses();
        }
    };

    const addCurrentCourses = () => {
        const currentDate = new Date();
        const aYearFromNow = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
        props.courses.forEach(function (course, index) {
            currentUser.currentCourses.push({
                course: course,
                creationTime: currentDate,
                endTime: aYearFromNow,
                finishedLessons: [],
                id: index + 1,
                lastLesson: null,
                updateTime: currentDate,
            });
        });
    };

    if(succeeded) {
        return <Redirect
            to={{
                pathname: '/payment-successful',
            }}/>;
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="checkout-wrapper">
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} className="form-control"/>
            <button
                className="btn btn-fixed btn-primary btn-shadow"
                disabled={processing || disabled || succeeded}
                id="submit"
            >
            <span id="button-text">
              {processing ? (
                  <LoadingIndicator marginTop={"6px"} size={30} color={"white"}/>
              ) : (
                  "Pay"
              )}
            </span>
            </button>
            <div className="buy-message-box">
                {
                    error && (
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    )
                }
            </div>
        </form>
    );
}