import { API_BASE_URL, ACCESS_TOKEN } from '../constants';

const request = (options) => {
    options = getOptions(options);

    return fetch(options.url, options)
    .then(response => 
        response.text().then(data => {
            const json = data ? JSON.parse(data) : {};
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

const requestText = (options) => {
    options = getOptions(options);

    return fetch(options.url, options)
        .then(response => {
                if(!response.ok) {
                    return response.text().then(data => {
                        const json = data ? JSON.parse(data) : {};
                        return Promise.reject(json);
                    })
                } else {
                    return response.text();
                }
            }
        );
};

function getOptions(options) {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    return Object.assign({}, defaults, options);
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.href);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getAllCourses() {
    return request({
        url: API_BASE_URL + "/courses/all",
        method: 'GET'
    });
}

export function getMyCourses() {
    return request({
        url: API_BASE_URL + "/courses/my",
        method: 'GET'
    });
}

export function getCourse(path) {
    return request({
        url: API_BASE_URL + "/courses/course?path=" + path,
        method: 'GET',
    });
}

export function getLessonContent(id) {
    return request({
        url: API_BASE_URL + "/lesson/content?id=" + id,
        method: 'GET',
    });
}

export function setCompleted(completedRequest) {
    return request({
        url: API_BASE_URL + "/lesson/completed",
        method: 'POST',
        body: JSON.stringify(completedRequest)
    });
}

export function setLastLesson(lessonCompletedRequest) {
    return request({
        url: API_BASE_URL + "/lesson/last-lesson",
        method: 'POST',
        body: JSON.stringify(lessonCompletedRequest)
    });
}

export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        const token = getUrlParameter('token');
        if(token) {
            localStorage.setItem(ACCESS_TOKEN, token);
        } else {
            return Promise.reject("No access token set.");
        }
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function accountRecovery(email) {
    return request({
        url: API_BASE_URL + "/auth/account-recovery?email=" + email,
        method: 'POST',
    });
}

export function passwordChange(passwordChangeRequest) {
    return request({
        url: API_BASE_URL + "/auth/password-change",
        method: 'POST',
        body: JSON.stringify(passwordChangeRequest)
    });
}

export function confirmEmail(token) {
    return request({
        url: API_BASE_URL + "/auth/confirm-email?token=" + token,
        method: 'POST',
    });
}

export function confirmEmailNewsletter(token) {
    return request({
        url: API_BASE_URL + "/newsletter/confirm-email?token=" + token,
        method: 'POST',
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function signupNewsletter(email) {
    return request({
        url: API_BASE_URL + "/newsletter/signup?email=" + email,
        method: 'POST',
    });
}

export function signupBootcamp(email) {
    return request({
        url: API_BASE_URL + "/bootcamp/signup?email=" + email,
        method: 'POST',
    });
}

export function createPaymentIntent(createPaymentIntentRequest) {
    return requestText({
        url: API_BASE_URL + "/buy/create-payment-intent",
        method: 'POST',
        body: JSON.stringify(createPaymentIntentRequest)
    });
}

export function submitPromoCode(code) {
    return request({
        url: API_BASE_URL + "/buy/submit-promo-code?code=" + code,
        method: 'POST',
    });
}

export function getAllPayments() {
    return request({
        url: API_BASE_URL + "/payment/all",
        method: 'GET'
    });
}