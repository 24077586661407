import * as React from 'react';
import defaultStyles from './styles';
import Preview from './Preview';
import FullscreenIcon from './FullscreenIcon';
import FullscreenButton from './FullscreenButton';
import Viewer from './Viewer';
import ProgressBar from './ProgressBar';
import PagingButton from './PagingButton';
import shallowEqualObject from './shallow-equal-object';
import toggleFullscreen, {
    fullscreenChange,
    isFullscreen
} from "toggle-fullscreen";

export default class SlideShow extends React.Component {
    constructor(props) {
        super(props);

        this.timestamp = 0;
        if (props.withTimestamp === true) {
            this.timestamp = Math.floor(new Date().getTime() / 1000);
        }

        let styles = {...defaultStyles};
        let root = {...styles.ROOT};

        if (props.style) {
            for (const key in props.style) {
                if (props.style.hasOwnProperty(key)) {
                    root[key] = props.style[key];
                }
            }
        }
        styles.ROOT = root;

        let image = {...styles.IMAGE};
        if (styles.ROOT.height) {
            image.height =
                styles.ROOT.height - styles.BAR.height - styles.PROGRESS_BAR.height + 5;
        }
        styles.IMAGE = image;

        this.state = {
            src: '',
            styles: styles,
            index: 0,
            progress: 0,
            preview: 0,
            previewIndex: 0,
            isFullScreen: false,
        };
    }

    componentWillMount() {
        const images = this.props.images;
        if (this.isEmptyArray(this.props.images)) {
            return;
        }
        let progress = Math.ceil(100 / images.length);
        if (progress > 100) {
            progress = 100;
        }

        this.setState({
            src: images[0],
            index: 0,
            progress: progress,
            preview: 0,
            previewIndex: 0,
            isFullScreen: false,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (shallowEqualObject(this.props, nextProps)) {
            return true;
        }

        if (shallowEqualObject(this.state, nextState)) {
            return true;
        }

        if (this.props.images.length !== nextProps.images.length) {
            return true;
        }

        for (let i = 0; i < this.props.images.length; i++) {
            const prev = this.props.images[i];
            const next = nextProps.images[i];
            if (prev !== next) {
                return true;
            }
        }
        return false;
    }

    onClickPrevButton = () => {
        if (this.isEmptyArray(this.props.images)) {
            return;
        }

        if (this.state.index === 0) {
            return;
        }

        const nextIndex = this.state.index - 1;
        this.updatePageState(nextIndex);
    };

    onClickNextButton = () => {
        if (!this.props.images) {
            return;
        }

        if (this.state.index === this.props.images.length - 1) {
            return;
        }
        const nextIndex = this.state.index + 1;
        this.updatePageState(nextIndex);
    };

    onClickProgressBar = (e) => {
        const nextIndex = this.calcProgressIndex(e);
        if (nextIndex === undefined || nextIndex === null) {
            return;
        }
        this.updatePageState(nextIndex);
    };

    onMouseMoveProgressBar = (e) => {
        const nextIndex = this.calcProgressIndex(e);
        this.setState({
            preview: 1,
            previewIndex: nextIndex,
        });
    };

    onMouseLeaveProgressBar = (e) => {
        this.setState({
            preview: 0,
        });
    };

    onChangeFullScreen = () => {
        const element: Object = document.getElementsByClassName(
            `${this.props.className}-wrapper`,
        )[0];
        const fn = () => {
            const isFullScreen = isFullscreen();
            this.setState({isFullScreen});
            if (isFullScreen) {
                document.addEventListener('keydown', this.keydownEvent);
                element.style.width = '70%';
            } else {
                document.removeEventListener('keydown', this.keydownEvent);
                element.style.width = '100%';
            }
        };
        Promise.all([toggleFullscreen(element), fullscreenChange(fn)]);
    };

    keydownEvent = (e) => {
        e.preventDefault();
        if (
            e.key === 'ArrowUp' ||
            e.key === 'ArrowLeft' ||
            e.keyCode === 37 ||
            e.keyCode === 38
        ) {
            this.onClickPrevButton();
        } else if (
            e.key === 'ArrowDown' ||
            e.key === 'ArrowRight' ||
            e.keyCode === 39 ||
            e.keyCode === 40 ||
            e.keyCode === 32
        ) {
            this.onClickNextButton();
        } else if (e.key === 'Escape' || e.keyCode === 27) {
            this.onChangeFullScreen();
        }
    };

    calcProgressIndex = (e) => {
        const parent = e.currentTarget.parentElement;
        if (!parent) {
            return;
        }
        const barWidth = parent.children[0].offsetWidth;
        const progressWidth =
            e.clientX - e.currentTarget.getBoundingClientRect().left;
        const clickPosition = Math.floor((progressWidth / barWidth) * 100);
        let nextIndex = 0;
        for (let i = 0; i < this.props.images.length; i++) {
            const checkWidth = this.calcProgress(i);
            if (clickPosition >= checkWidth) {
                nextIndex = i;
            }
        }
        return nextIndex;
    };

    calcProgress = (page) => {
        const base = 100 / this.props.images.length;
        const progress = Math.ceil(base * page);
        if (progress > 100) {
            return 100;
        }
        return progress;
    };

    isEmptyArray = (arr) => {
        return arr === undefined || arr === null || arr.length === 0;
    };

    updatePageState = (index) => {
        const progress = this.calcProgress(index + 1);
        const image = this.props.images[index];
        this.setState({
            src: image,
            index: index,
            progress: progress,
        });
        this.props.pageWillUpdate(index, image);
    };

    render() {
        // let styles = {...this.state.styles};
        // if (this.props.style) {
        //     for (const key in this.props.style) {
        //         if (this.props.style.hasOwnProperty(key)) {
        //             styles.ROOT[key] = this.props.style[key];
        //             this.setState({
        //                 styles: styles,
        //             });
        //         }
        //     }
        // }
        // if (this.state.styles.ROOT.height) {
        //     this.state.styles.IMAGE.height =
        //         this.state.styles.ROOT.height - this.state.styles.BAR.height - this.state.styles.PROGRESS_BAR.height + 5;
        // }
        return (
            <div style={this.state.styles.ROOT} className={this.props.className}>
                <div
                    className={`${this.props.className}-wrapper`}
                    style={{margin: 'auto'}}
                >
                    <Viewer
                        imgClassName={`${this.props.className}-image`}
                        styles={this.state.styles}
                        src={this.state.src}
                        onClickPrevButton={this.onClickPrevButton}
                        onClickNextButton={this.onClickNextButton}
                        timestamp={this.timestamp}
                    />
                    <Preview
                        opacity={this.state.preview}
                        previewIndex={this.state.previewIndex}
                        images={this.props.images}
                        isFullScreen={this.state.isFullScreen}
                        imgClassName={`${this.props.className}-image`}
                    />
                    <ProgressBar
                        style={this.state.styles.PROGRESS_BAR}
                        onClick={this.onClickProgressBar}
                        onMouseMove={this.onMouseMoveProgressBar}
                        onMouseLeave={this.onMouseLeaveProgressBar}
                        progress={this.state.progress}
                    />
                    <div style={this.state.styles.BAR}>
                        <PagingButton onClick={this.onClickPrevButton}>
                            {this.props.prevIcon}
                        </PagingButton>
                        <span style={this.state.styles.PAGE_VIEW}>{`${this.state.index + 1} / ${
                            this.props.images ? this.props.images.length : 0
                            }`}</span>
                        <PagingButton onClick={this.onClickNextButton}>
                            {this.props.nextIcon}
                        </PagingButton>
                        {this.props.showFullscreenIcon ? (
                            <FullscreenButton onClick={this.onChangeFullScreen}>
                                <FullscreenIcon isFullScreen={this.state.isFullScreen}/>
                            </FullscreenButton>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

SlideShow.defaultProps = {
    arrowButtonStyle: defaultStyles.ARROW_BUTTON,
    style: {},
    images: [],
    prevIcon: (
        <svg style={defaultStyles.ARROW_BUTTON} viewBox="0 0 8 8">
            <path
                fill="#fff"
                d="M4 0l-4 3 4 3v-6zm0 3l4 3v-6l-4 3z"
                transform="translate(0 1)"
            />
        </svg>
    ),
    nextIcon: (
        <svg style={defaultStyles.ARROW_BUTTON} viewBox="0 0 8 8">
            <path
                fill="#fff"
                d="M0 0v6l4-3-4-3zm4 3v3l4-3-4-3v3z"
                transform="translate(0 1)"
            />
        </svg>
    ),
    withTimestamp: false,
    pageWillUpdate: (index, image) => {
        return;
    },
    className: 'slideshow',
    showFullscreenIcon: true,
};
