import React, {Component} from 'react';
import './Post.css';
import DateFormatter from "../common/DateFormatter";
import {Editor} from "react-draft-wysiwyg";
import {customRenderer} from "../editor/util/RendererUtils";
import {STYLE_MAP} from "../lessons/lesson/Lesson";

import {blogPosts} from "./data/postsLoader";
import {convertFromRaw, EditorState} from "draft-js";
import PostAuthor from "./PostAuthor";

class Post extends Component {

    constructor(props) {
        super(props);

        const {postPath} = this.props.match.params;
        const post = blogPosts.find(p => p.path === postPath)

        this.state = {
            post: post,
            lessonContent: EditorState.createWithContent(convertFromRaw(post.content)),
        };
    }

    render() {
        return (
            <div className="post-wrapper">
                <h1 className="row-header" style={{marginBottom: 20}}>
                    {this.state.post.title}
                </h1>
                <div className="post-post-author">
                    <PostAuthor post={this.state.post}/>
                </div>
                <img src={this.state.post.imageUrl} className="post-image" alt={this.state.post.title}/>
                <div className="post-content">
                    <Editor
                        customStyleMap={STYLE_MAP}
                        editorState={this.state.lessonContent}
                        readOnly={true}
                        toolbarHidden={true}
                        customBlockRenderFunc={customRenderer}
                        editorClassName="rte-reader"
                    />
                </div>
            </div>
        )
    }
}

export default Post