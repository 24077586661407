import aceAnyCodingInterview from './aceAnyCodingInterview.json';

export const blogPosts = [
    {
        title: "How to ace any coding interview",
        shortDesc: "Few tips for your coding interview preparation",
        author: "Daniel",
        authorImageUrl: "https://cdn.huro.io/daniel-avatar.png",
        date: 1695716075,
        path: "ace-coding-interview",
        imageUrl: "https://cdn.huro.io/blog/ace-coding-interview.jpg",
        content: aceAnyCodingInterview,
    }
]