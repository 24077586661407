import React, {Component} from 'react';
import './Lesson.css';
import LoadingIndicator from "../../common/LoadingIndicator";
import {AppContext} from "../../context/AppContext";
import {getCourse, getLessonContent, setCompleted, setLastLesson} from "../../util/APIUtils";
import Alert from "react-s-alert";
import Section from "../../common/section/Section";
import {Link} from "react-router-dom";
import {EditorState, convertFromRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {customRenderer} from "../../editor/util/RendererUtils";

export const STYLE_MAP = {
    'CODE': {
        color: 'rgb(56, 58, 66)',
        background: 'rgb(250, 250, 250)',
        padding: '3px',
        fontFamily: 'monospace',
        whiteSpace: 'pre'
    },
};

class Lesson extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        const course = this.props.location.course;
        this.state = {
            course: course ? course : null,
            lesson: null,
            prevLesson: null,
            nextLesson: null,
            lessonContent: null,
            collapseMenu: window.innerWidth < 768,
            isCompleted: true,
            scrollTop: 0,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCompleted = this.toggleCompleted.bind(this);
        this.setCompleted = this.setCompleted.bind(this);
    }

    componentDidMount() {
        const {coursePath, lessonOrder} = this.props.match.params;

        if (this.context.hideHeader) {
            this.context.toggleHeader();
        }

        this.getCourseOrIgnore(coursePath)
            .then(response => {
                if (response) {
                    this.setState({
                        course: response,
                    });
                }

                const lesson = this.setLesson(parseInt(lessonOrder, 10));
                if (lesson == null) {
                    throw {
                        status: 404
                    }
                }
                return getLessonContent(lesson.id);
            })
            .then(response => {
                this.setState({
                    lessonContent: EditorState.createWithContent(convertFromRaw(response)),
                    isCompleted: response && !this.state.nextLesson ? this.isLessonCompleted() : true,
                });

                if (this.context.currentUser && this.isCourseBought()) {
                    return setLastLesson({
                        courseId: this.state.course.id,
                        lessonId: this.state.lesson.id
                    });
                }
            })
            .then(response => {
                if (response) {
                    this.context.loginApp(response);
                }

                const {hash} = window.location;
                if (hash !== '') {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) element.scrollIntoView();
                }
            })
            .catch(error => {
                if (error.status === 404) {
                    this.props.history.push("/not-found");
                } else if (error.status === 401) {
                    this.setState({
                        lessonContent: "",
                    });
                } else {
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                        timeout: 5000
                    });
                }
            });
    }

    onScroll = () => {
        const scrollTop = this.myRef.current.scrollTop;
        const maxScroll = 40;

        this.setState(prevState => {
            if ((prevState.scrollTop <= maxScroll && scrollTop > maxScroll) || (prevState.scrollTop > maxScroll && scrollTop <= maxScroll)) {
                this.context.toggleHeader();
            }
            return {scrollTop: scrollTop};
        });
    };

    getCourseOrIgnore(coursePath) {
        if (this.state.course) {
            return Promise.resolve();
        } else {
            return getCourse(coursePath);
        }
    }

    setLesson(lessonOrder) {
        let lesson = null;
        let prevLesson = null;
        for (let i = 0; i < this.state.course.sections.length; i++) {
            for (let j = 0; j < this.state.course.sections[i].lessons.length; j++) {
                const l = this.state.course.sections[i].lessons[j];
                if (l.lessonOrder === lessonOrder) {
                    lesson = l;
                    this.setState({
                        lesson: l,
                        prevLesson: prevLesson,
                    });
                } else if (this.state.lesson !== null) {
                    this.setState({
                        nextLesson: l,
                    });
                    break;
                }
                prevLesson = l;
            }
            if (this.state.lesson !== null && this.state.nextLesson !== null) {
                break;
            }
        }
        return lesson;
    }

    toggleCollapse() {
        this.setState(prevState => ({
            collapseMenu: !prevState.collapseMenu
        }));
    };

    toggleCompleted() {
        this.setState(prevState => ({
            isCompleted: !prevState.isCompleted
        }), () => {
            this.setCompleted();
        });
    };

    setCompleted() {
        if (this.context.currentUser && this.isCourseBought() && this.state.isCompleted !== this.isLessonCompleted()) {
            setCompleted({
                courseId: this.state.course.id,
                lessonId: this.state.lesson.id,
                isCompleted: this.state.isCompleted
            })
                .then(response => {
                    this.context.loginApp(response);
                })
                .catch(error => {
                    Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!', {
                        timeout: 5000
                    });
                });
        }
    };

    isLessonCompleted() {
        return this.context.currentUser.currentCourses.some(c => c.finishedLessons.some(l => l.id === this.state.lesson.id));
    }

    isCourseBought() {
        return this.context.currentUser.currentCourses.some(c => c.course.id === this.state.course.id);
    }

    render() {
        const {currentUser, hideHeader} = this.context;

        if (!this.state.course && !this.state.lesson) {
            return <LoadingIndicator/>
        }

        const showCompletedCbx = this.state.lessonContent && currentUser;
        return (
            <div className="lesson-wrapper">
                <div
                    className={'lesson-left-panel ' + (hideHeader ? 'lesson-left-panel-long ' : '') + (this.state.collapseMenu ? '' : 'collapse-phone')}
                    ref={this.myRef} onScroll={this.onScroll}>
                    <div>
                        {
                            this.state.lessonContent === null ? (
                                <LoadingIndicator/>
                            ) : (
                                (!this.context.currentUser && !this.state.lesson.preview) || this.state.lessonContent === '' ? (
                                    <React.Fragment>
                                        This lesson is not available in the preview. <Link to={{
                                        pathname: `/buy/${this.state.course.path}`,
                                        state: {courses: [this.state.course]}
                                    }} className="common-link">Buy this course</Link> to start learning.
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="lesson-content-title">{this.state.lesson.name}</div>
                                        <Editor
                                            customStyleMap={STYLE_MAP}
                                            editorState={this.state.lessonContent}
                                            readOnly={true}
                                            toolbarHidden={true}
                                            customBlockRenderFunc={customRenderer}
                                            editorClassName="rte-reader"
                                        />
                                    </React.Fragment>
                                )
                            )
                        }
                    </div>
                    <div className="lesson-buttons">
                        {
                            this.state.prevLesson ? (
                                <div className="lesson-back" style={showCompletedCbx ? {paddingTop: '25px'} : {}}>
                                    <Link to={{
                                        pathname: `/course/${this.state.course.path}/${this.state.prevLesson.lessonOrder}`,
                                        course: this.state.course
                                    }} className="btn btn-fixed btn-secondary">Back</Link>
                                    <div className="lesson-buttons-titles">{this.state.prevLesson.name}</div>
                                </div>
                            ) : null
                        }
                        <div className="lesson-footer-filler"/>
                        <div className="lesson-next">
                            {
                                showCompletedCbx ? (
                                    <div className="lesson-cbx-wrapper">
                                        <div className="lesson-cbx">
                                            <input type="checkbox" id="cbx" style={{display: "none"}}
                                                   defaultChecked={this.state.isCompleted}
                                                   onChange={this.toggleCompleted}/>
                                            <label htmlFor="cbx" className="toggle"><span/></label>
                                        </div>
                                        <div className="lesson-cbx-label">
                                            {
                                                this.state.isCompleted ? (
                                                    <div>Completed</div>
                                                ) : (
                                                    <div>Incompleted</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.nextLesson ? (
                                    <React.Fragment>
                                        <Link to={{
                                            pathname: `/course/${this.state.course.path}/${this.state.nextLesson.lessonOrder}`,
                                            course: this.state.course
                                        }} className="btn btn-fixed btn-primary" onClick={this.setCompleted}>Next</Link>
                                        <div className="lesson-buttons-titles">{this.state.nextLesson.name}</div>
                                    </React.Fragment>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.collapseMenu ? (
                        <div className="lesson-menu-open" onClick={this.toggleCollapse}>
                            <div className="lesson-arrow-open">❮</div>
                            Course content</div>
                    ) : null
                }
                <div className={'lesson-right-panel ' + (this.state.collapseMenu ? 'collapse' : null)}>
                    <div className="lesson-right-panel-title">
                        <div className="lesson-menu-title">Course content</div>
                        <div className="lesson-menu-close" onClick={this.toggleCollapse}/>
                    </div>
                    <div
                        className={'lesson-sections-container ' + (hideHeader ? 'lesson-sections-container-long' : null)}>
                        {this.state.course.sections.map((section, index) => {
                            return <Section course={this.state.course} section={section} key={index}
                                            lesson={this.state.lesson}/>
                        })}
                        <div className="lesson-right-panel-filler">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Lesson