import React, {Component} from 'react';
import './FooterWrapper.css';
import Footer from "./Footer";

class FooterWrapper extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="app-body container">
                    {this.props.children}
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default FooterWrapper;
