import React, {Component} from "react";
import PostCard from "./PostCard";
import {blogPosts} from "./data/postsLoader";

class PostsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: blogPosts
        };
    }

    render() {
        return (
            <React.Fragment>
                <h1 className="row-header">
                    Huro blog
                </h1>
                <div className="post-tiles-container">
                    {
                        this.state.posts.map((value, index) => {
                            return <PostCard post={value} index={index} key={index}/>
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default PostsList